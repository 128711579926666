import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useSelector, useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"
import dateFormat from "dateformat"
import Container from "@material-ui/core/Container"

import {
//   setBasicPumpSizingFromSaved,
  removeQuoteSave,
  getQuoteSaveList,
} from "../../state/store"
import MaterialTable from "material-table"

import {
  Edit,
  Delete,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  Clear,
  ArrowUpward,
  Add,
  Check,
} from "@material-ui/icons"

const HistoryList = () => {
  const { userProfile, quotes } = useSelector(state => state.db)
  const dispatch = useDispatch()

  useEffect(() => {
    if (userProfile.id) {
      dispatch(getQuoteSaveList())
    }
  }, [userProfile])
  return (
    <>
      {quotes && userProfile && quotes.loading && (
        <CircularProgress />
      )}
      <div style={{ overflowX: "auto" }}>
        {quotes && quotes.data && quotes.data.length > 0 && (
          <MaterialTable
            icons={{
              Edit,
              Delete,
              FirstPage,
              LastPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              ResetSearch: Clear,
              Search,
              SortArrow: ArrowUpward,
              Add,
              Check,
              Clear,
            }}
            columns={[
              {
                title: "Date",
                field: "updated",
                searchable: false,
                render: rowData => (
                  <span>
                    {rowData.updated &&
                      dateFormat(
                        new Date(rowData.updated.seconds * 1000),
                        "dd mmm yyyy HH:MM"
                      )}
                  </span>
                ),
              },
            //   { title: "Type", field: "type", searchable: false },
            {
                title: "Quote Ref",
                field: "quoteRef",
                render: rowData => <span>{rowData.quoteRef}</span>,
              },
              {
                title: "Customer",
                field: "client.customer",
                render: rowData => <span>{rowData.client?.customer}</span>,
              },
              {
                title: "Email",
                field: "client.email",
                render: rowData => <span>{rowData.client?.email}</span>,
              },
              // {
              //   title: "Ref",
              //   field: "internalRef",
              //   render: rowData => <span>{rowData.internalRef}</span>,
              // },
   
            ]}
            actions={[
              () => ({
                icon: Edit,
                tooltip: "Show",
                onClick: (event, rowData) => {
                  navigate("/app/quote/", {
                    state: { saved: rowData, sizingData: rowData.sizingData  },
                  })
                },
              }),
              () => ({
                icon: Delete,
                tooltip: "Delete",
                onClick: (event, rowData) =>
                  dispatch(removeQuoteSave(rowData.id)),
              }),
            ]}
            options={{
              pageSize: 20,
              actionsColumnIndex: -1,
            }}
            data={JSON.parse(JSON.stringify(quotes.data))}
            title="Saved Quotes"
          />
        )}
      </div>
    </>
  )
}

const AppQuoteHistory = () => {
  //   const { products } = useSelector(state => state.db)
  return (
    <Container component="main" maxWidth="md">
      <Box mb={2}>
        <Typography component="h1" variant="h4">
          Saved Quotes
        </Typography>
        <Typography component="p" variant="body1">
          See your saved quotes below. Make a quote by doing a sizing or open up an existing quote below.
        </Typography>
      </Box>
      <HistoryList />
      </Container>
  )
}

export default AppQuoteHistory
