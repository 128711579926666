import React, { useEffect, useState, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { getPublicStock } from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
  buttonBottom: theme.spacing(2),
  toolbarRow: {
    display: "flex",
    justifyContent: "space-between",
  },
}))

const validateOrder = list => {
  return (
    list.length > 0 &&
    list.filter(
      a => a.product === undefined || a.amount === NaN || a.quantity === undefined  || a.quantity <= 0
    ).length === 0
  )
}


const AppProductOrder = () => {
  const { publicStock, userProfile, order } = useSelector(state => state.db)
  const dispatch = useDispatch()
  const [orderList, setOrderList] = useState([])
  const tableRef = useRef()
  const [prevSent, setPrevSent] = useState(null)
  const [showDoubleError, setShowDoubleError] = useState(false)

  useEffect(() => {
    dispatch(getPublicStock())
  }, [dispatch])

  const classes = useStyles()

  return (
    <Container component="main" maxWidth="md">
      <Box mb={2}>
        <Typography component="h1" variant="h4">
          Online Orders have moved
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography component="h2" variant="h5">
          Head over to products to try the new way of ordering online.
        </Typography>
      </Box>
    </Container>
  )
}

export default AppProductOrder
