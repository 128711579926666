import React, { useEffect, useState, useReducer } from 'react';
import { Link, navigate } from 'gatsby'
import { DataGrid } from '@mui/x-data-grid';
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"
import { useSelector, useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from "@material-ui/core/Button"
import sum from 'lodash/sum'
import { roundCashFormat, getStockValue, sortProducts, getPriceBasedOnRoleNocodb, getStockBasedOnProfileLocation } from "../../utils/common"
import { Table, TableRow, TableCell, TableBody, Grid } from '@material-ui/core';
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getCurrentOrder, updateCurrentOrder, addOrder, finishAddOrder, getCategoriesList, getProductsList } from '../../state/store';
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import AlertDialog from '../dialog';
import StockIcon from '../stockIcon';

const useStyles = makeStyles(theme => ({
    autoComp: {
        lineHeight: 0
    }
}))


const totalExclVat = (rows) => sum(rows.map(a => (a.quantity || 0) * (a.price || 0)))
// const totalWithMarkup = (rows) => sum(rows.map(a => (subtotalWithMarkup({ markup: a.markup, price: a.priceInstaller, quantity: a.quantity }))))

export const ProductSearchBox = ({ initialValue, list, onChange }) => {
    const [value, setValue] = React.useState(initialValue ?? null);
    const classes = useStyles()
    return (
        <Autocomplete
            className={classes.autoComp}
            value={value}
            getOptionSelected={(option, value) => option.Code === value.Code}
            onChange={(event, newValue, _, detail) => {
                setValue(newValue);
                onChange(newValue)
            }}
            groupBy={(option) => option.Categories.Title}
            selectOnFocus
            handleHomeEndKeys
            id="product-select"
            options={sortProducts(list)}
            getOptionLabel={(option) => {
                // // Value selected with enter, right from the input
                // if (typeof option === 'string') {
                //     return option;
                // }
                // // Add "xxx" option created dynamically
                // if (option.inputValue) {
                //     return option.inputValue;
                // }
                // Regular option
                return option.Title;
            }}

            renderOption={(option) => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}><span>{option.Title}</span><StockIcon stock={option.stock} /></div>}
            style={{ flex: 1 }}
            renderInput={(params) => (
                <TextField {...params} label="Product Search" variant="outlined" />
            )}
        />
    );
}



export const CustomFooter = ({ rows, showVat }) => {
    return <Table>
        <TableBody>
            {/* <TableRow>
                <TableCell align="right">
                    <Typography style={{ fontWeight: 'bold' }}>
                        Discount 1%
                    </Typography>
                </TableCell>
                <TableCell align="right" width={200}>
                    {roundCashFormat(totalExclVat(rows) * 0.01)}
                </TableCell>
            </TableRow> */}
            <TableRow>
                <TableCell align="right">
                    <Typography style={{ fontWeight: 'bold' }}>
                        Total (ex VAT)
                    </Typography>
                </TableCell>
                <TableCell align="right" width={200}>
                    {roundCashFormat(totalExclVat(rows))}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right">
                    <Typography style={{ fontWeight: 'bold' }}>
                        VAT
                    </Typography>
                </TableCell>
                <TableCell align="right" width={200}>
                    {roundCashFormat(totalExclVat(rows) * 0.15)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right">
                    <Typography style={{ fontWeight: 'bold' }}>
                        Total
                    </Typography>
                </TableCell>
                <TableCell align="right" width={200}>
                    {roundCashFormat(totalExclVat(rows) * 1.15)}
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>

}


const initialQuoteState = {
    client: null,
    rows: [],
    specialInstructions: '',
    showVat: true,
    isNew: true,
    collect: false,
    clientRef: '',
    outOfStock: false,
    invalidQuantity: false,
}

const quoteReducer = (state, action) => {
    switch (action.type) {
        // case 'SET_QUOTE_REF':
        //     return { ...state, quoteRef: action.payload };
        case 'SET_SPECIAL_INSTRUCTIONS':
            return { ...state, specialInstructions: action.payload };
        case 'SET_ROWS':
            return { ...state, rows: action.payload, isNew: false };
        // case 'SET_CLIENT':
        //     return { ...state, client: action.payload };
        case 'SET_DELIVERY':
            return { ...state, collect: action.payload };
        case 'SET_CLIENT_REF':
            return { ...state, clientRef: action.payload };
        case 'SET_OUT_OF_STOCK':
            return { ...state, outOfStock: action.payload };
        case 'SET_INVALID_QUANTITY':
            return { ...state, invalidQuantity: action.payload };
        default:
            throw new Error();
    }
}

const quoteRefGenerator = (id) => {
    const seconds = Math.floor((new Date().getTime()) / 1000).toString().substring(1, 8)
    return (id.substring(0, 2) + seconds).toUpperCase()
}

const AppProductOrderSage = ({ location }) => {


    const dispatch = useDispatch()

    const { userProfile, currentOrder, order, products, categories } = useSelector(state => state.db)

    const [quoteState, quoteDispatch] = useReducer(quoteReducer, initialQuoteState);

    const [productsList, setProductsList] = useState([]) // the list of products from shopify

    const [selectedRows, setSelectedRows] = useState([])


    useEffect(() => {
        dispatch(getCategoriesList())
        dispatch(getProductsList())
    }, [])

    useEffect(() => {
        if (userProfile && userProfile.roles && products && products.data && products.data.length > 0 && categories && categories.data) {
            // making sure there is a category for each product
            const productsList = products.data.filter(a => !!a.Categories && categories.data.find(category => `${category.Id}` === `${a.Categories.Id}`)).map(p => {
                return {
                    ...p,
                    // category: category ? category : { Title: 'Uncategorised' },
                    price: getPriceBasedOnRoleNocodb({ roles: userProfile.roles, product: p }),
                    stock: getStockBasedOnProfileLocation({ location: userProfile?.warehouse, product: p }),
                }
            })
            setProductsList(productsList)

        }
    }, [products, categories, userProfile])


    useEffect(() => {
        if (userProfile && productsList) {
            dispatch(getCurrentOrder())

        }
    }, [userProfile, productsList])

    // so I think this is hear to only make this happen on first load
    // I need it to happen after the products list is loaded
    useEffect(() => {
        if (quoteState.rows && quoteState.rows.length === 0 && currentOrder && currentOrder.data && currentOrder.data.rows && quoteState.isNew && products && products.data) {

            quoteDispatch({
                type: 'SET_ROWS', payload: currentOrder.data.rows
            })
        }
    }, [currentOrder, products])



    useEffect(() => {
        if (currentOrder) {
            // remove blanks
            const rows = quoteState.rows.filter(row => !!row.code)
            dispatch(updateCurrentOrder({ rows }))
        }
    }, [quoteState.rows])


    const columns = [
        {
            field: 'code', headerName: 'Name', flex: 1,
            minWidth: 300,
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (param) => {
                return <ProductSearchBox initialValue={productsList.find(a => param.value === a.Code)} list={productsList} onChange={(e) => {
                    quoteDispatch({
                        type: 'SET_ROWS', payload: quoteState.rows.map(a => {
                            if (a.id === param.id && e) {
                                return ({ ...a, product: e, name: e.Title ?? e, price: e.price ?? 0, stock: getStockBasedOnProfileLocation({ location: userProfile?.warehouse, product: e }), code: e.Code })
                            } else {
                                return a
                            }
                        })
                        // filter out duplicates
                        .filter((thing, index, self) =>
                            index === self.findIndex((t) => (
                                t.code === thing.code
                            ))
                        )

                    })
                }} />
            }
        },
        {
            field: 'stock',
            headerName: 'Stock',
            width: 90,
            editable: false,
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            // renderCell: (param) => {
            //     return <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', width: '100%' }}><Typography variant="body2">{param.value}</Typography></div>
            // }

        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            width: 90,
            editable: true,
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
        },
        {
            field: 'price',
            headerName: 'Price (excl VAT)',
            type: 'number',
            width: 200,
            editable: false,
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            valueFormatter: (params) => roundCashFormat(params.value)

        },
        {
            field: 'subtotal',
            headerName: 'Sub Total',
            type: 'number',
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            editable: false,
            disableColumnMenu: true,
            valueFormatter: (params) => roundCashFormat(params.value),
            width: 200,
            valueGetter: (params) =>
                `${(params.getValue(params.id, 'quantity') || 0) * (params.getValue(params.id, 'price') || 0)}`,
        },
    ];

    return (
        <Container component="main" maxWidth="lg">
            <Box mb={2}>
                <Typography component="h1" variant="h4">
                    Shopping Cart
                </Typography>
            </Box>
            <Typography component="p" variant="body1" gutterBottom>
                Browse our products on the shopping page <Link to="/app/product/categories/">here</Link> and add them to this cart.
            </Typography>
            <Typography component="p" variant="body1" gutterBottom>
                You can also use the table below to add items you want to order. Prices will be quoted based on your pricing tier.
                Click "Add Product to Order" to add a new product to your order. You can start typing to search for the product you are looking for. Double click on the quantity to change the quantity of the product.
            </Typography>
            <Typography component="p" variant="body1" gutterBottom>
                If you looking for more information on our products, please head over to the shopping page for information and pictures shopping page <Link to="/app/product/categories/">here</Link>. If you having trouble with this form you can order via email at admin@cedarsolar.com.
            </Typography>

            {/* {quoteState.outOfStock && <AlertDialog show={quoteState.outOfStock} title="Cannot Send, items out of stock" message="Some items added to your order are out of stock. You cannot submit with out of stock items. Please remove and try again." closeDialog={() => quoteDispatch({ type: 'SET_OUT_OF_STOCK', payload: false })} />} */}

            {quoteState.invalidQuantity && <AlertDialog show={quoteState.invalidQuantity} title="Cannot Send, quantity error" message="Some items in your cart have a quantity of less than 1. Please add at least 1 item and try again." closeDialog={() => quoteDispatch({ type: 'SET_INVALID_QUANTITY', payload: false })} />}

            <Dialog open={userProfile && !userProfile.companyName} onClose={() => console.log('done')}>
                <DialogTitle>Please fill out your company details</DialogTitle>
                <DialogContent>
                    <Typography component="p" variant="body1" gutterBottom>
                        We require some more information before we can proceed. Please update your company details below in your profile. Click Profile below to be redirected to your profile.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => navigate('/app/profile/')} color="primary">
                        Go to Profile
                    </Button>
                </DialogActions>
            </Dialog>
            <Box marginBottom={'10px'}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>

                        quoteDispatch({ type: 'SET_ROWS', payload: [...quoteState.rows.filter(a => !!a.code), { id: quoteState.rows && quoteState.rows.length > 0 ? quoteState.rows[quoteState.rows.length - 1].id + 1 : 1, quantity: 1, price: 0 }] })
                    }
                >
                    Add Product to Order
                </Button>
                {selectedRows && selectedRows.length > 0 && <Button
                    style={{ marginLeft: '20px' }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        quoteDispatch({ type: 'SET_ROWS', payload: quoteState.rows.filter(a => !selectedRows.find(b => b === a.id)) })
                        setSelectedRows([])

                    }}
                >
                    Delete Selected
                </Button>}
                {quoteState.rows && quoteState.rows.length > 0 && <Button
                    style={{ marginLeft: '20px' }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        quoteDispatch({ type: 'SET_ROWS', payload: [] })
                        setSelectedRows([])

                    }}
                >
                    Clear All
                </Button>}
            </Box>

            {productsList && productsList.length === 0 && <CircularProgress />}

            {productsList && <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={quoteState.rows}
                    columns={columns}
                    pageSize={50}
                    checkboxSelection
                    disableSelectionOnClick
                    rowHeight={80}
                    onSelectionModelChange={(e) => {
                        setSelectedRows(e)
                    }}
                    onCellEditCommit={(e) => {
                        quoteDispatch({
                            type: 'SET_ROWS',
                            payload: quoteState.rows.map(a => {
                                console.log(a)
                                if (e.row && (a.id === e.row.id)) {
                                    let row = { ...e.row }
                                    row[e.field] = e.value
                                    return { ...row }
                                } else if (a.id === e.id) {
                                    let row = { ...a }
                                    row[e.field] = e.value
                                    return { ...row }
                                } else {
                                    return a
                                }

                            })
                        })

                    }}
                    components={{
                        Footer: CustomFooter
                    }}
                    componentsProps={{
                        footer: { rows: quoteState.rows },
                    }}
                />
            </div>}
            <Box marginBottom={'10px'}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={e => quoteDispatch({ type: 'SET_DELIVERY', payload: !e.target.checked })}
                            checked={!quoteState.collect}
                        />
                    }
                    label="Add Delivery to Order (we will contact you to confirm delivery details)"
                />
                <div style={{ display: 'flex' }}>
                    <Typography
                        component="p"
                        variant="body2"
                    >
                        Pickup Location (closest warehouse):
                    </Typography>
                    <Typography
                        component="p"
                        variant="body2"
                        style={{ fontWeight: "bold", paddingLeft: "2px" }}
                    >
                        {userProfile ? userProfile.warehouse : "Johannesburg"}
                    </Typography>
                </div>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Box marginBottom={'10px'} maxWidth={'300px'}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel htmlFor="Ref">Optional Reference/Purchase Order</InputLabel>

                            <OutlinedInput
                                labelWidth={260}
                                id="Ref"
                                placeholder="Reference optional"
                                value={quoteState.clientRef}
                                onChange={e => quoteDispatch({ type: 'SET_CLIENT_REF', payload: e.target.value })}
                            />
                        </FormControl>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={8}>

                    <Box marginBottom={'10px'} maxWidth={'400px'}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel htmlFor="Ref">Special Instructions</InputLabel>

                            <OutlinedInput
                                multiline
                                rows={4}
                                label="Special Instructions"
                                id="Special Instructions"
                                placeholder="Special instructions optional"
                                value={quoteState.specialInstructions}
                                onChange={e => quoteDispatch({ type: 'SET_SPECIAL_INSTRUCTIONS', payload: e.target.value })}
                            />
                        </FormControl>

                    </Box>
                </Grid>
            </Grid>
            <Box marginTop={'10px'} marginBottom={'10px'}>
                {order && order.loading && <CircularProgress />}
                {order && order.msg && <AlertDialog show={true} message={order.msg} title={order.error ? 'Error' : 'Sent'} closeDialog={() => {
                    if (!order.error) {
                        quoteDispatch({ type: 'SET_ROWS', payload: [] })
                        quoteDispatch({ type: 'SET_CLIENT_REF', payload: '' })


                    }
                    dispatch(finishAddOrder({
                        loading: false,
                        msg: null,
                        error: null,
                        data: null,
                    }))
                }} />}
                <Button
                    variant="contained"
                    color="primary"
                    disabled={(order && order.loading) || quoteState.rows.length === 0 || quoteState.rows.filter(row => !!row.code).length === 0}
                    onClick={() => {
                        // remove duplicates and empty rows
                        const rows = quoteState.rows.filter((thing, index, self) =>
                            index === self.findIndex((t) => (
                                t.code === thing.code
                            ))
                        ).filter(row => !!row.code)
                        // console.log(rows)
                        // check if any items have a quantity of 0 or less
                        const invalidQuantity = rows.filter(row => row.quantity <= 0)
                        if (invalidQuantity.length > 0) {
                            quoteDispatch({ type: 'SET_INVALID_QUANTITY', payload: true })
                            return
                        } else {
                            quoteDispatch({ type: 'SET_INVALID_QUANTITY', payload: false })
                        }
                        // check if any items are out of stock
                        // const outOfStock = rows.filter(row => row.stock < row.quantity)
                        // if (outOfStock.length > 0) {
                        //     quoteDispatch({ type: 'SET_OUT_OF_STOCK', payload: true })
                        // } else {
                        //     quoteDispatch({ type: 'SET_OUT_OF_STOCK', payload: false })
                        //     dispatch(addOrder({ rows, collect: quoteState.collect, specialInstructions: quoteState.specialInstructions ?? '', clientRef: quoteState.clientRef ? quoteState.clientRef : quoteRefGenerator(userProfile.id) }))
                        // }
                        // now we allowing orders with out of stock items
                        dispatch(addOrder({ rows, collect: quoteState.collect, specialInstructions: quoteState.specialInstructions ?? '', clientRef: quoteState.clientRef ? quoteState.clientRef : quoteRefGenerator(userProfile.id) }))

                    }}
                >
                    Submit to Cedar
                </Button>
            </Box>

            <Box marginTop={'10px'} marginBottom={'10px'}>
                <Typography variant='subtitle2'>*You can access this later, it will remember your last inputs (i.e. you can submit later)</Typography>
            </Box>


        </Container>)

}

export default AppProductOrderSage
