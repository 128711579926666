import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import { addToCurrentOrder, getCategoriesList, getProductsList } from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"
import { Link } from "gatsby"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import CardActionArea from "@material-ui/core/CardActionArea"
import { roundCashFormat, convertImageFromNocodb, getPriceBasedOnRoleNocodb, getStockBasedOnProfileLocation } from "../../utils/common"
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Alert } from "@material-ui/lab"
import { canAccessStock } from '../../utils/checkRoles'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
  root: {
    maxWidth: 345,
    height: '100%'
  },
  media: {
    height: 160,
    backgroundSize: 'contain'
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  breadcrumbLink: {
    textDecoration: 'none',
    '& :hover': {
      textDecoration: 'underline',
    }
  }
}))


const AppProductsList = ({ location }) => {

  const { categories, products, userProfile } = useSelector(state => state.db)
  const dispatch = useDispatch()
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)

  const [showNoCategory, setShowNoCategory] = useState(false)

  useEffect(() => {
    if (didJustAddToCart) {
      setTimeout(() => {
        setDidJustAddToCart(false)
      }, 3000)
    }
  }, [didJustAddToCart])

  useEffect(() => {
    dispatch(getCategoriesList())
    dispatch(getProductsList())
  }, [])

  const [collection, setCollection] = useState(null)



  useEffect(() => {   
    // this is for the case where the user goes to /app/product/categories/products/ directly
    if(location && location.state === null){
      setShowNoCategory(true)
    }
    else
    if (location && !!location.state && products && products.data, categories && categories.data) {
      setCollection(products.data.filter(product => !!product.Categories).filter(product => product.Categories.Id == location.state.collectionId)
        .map(product => {
          if (!product.Image) {
            const Image = categories.data.find(a => a.Id == product.Categories.Id)?.Image
            return { ...product, Image }
          }
          return product
        })
      )
    }

    

  }, [location, products])

  const classes = useStyles()
  return (
    <Container component="main" maxWidth="md">

      <Breadcrumbs aria-label="breadcrumb" className={classes.paddingBottom}>
        <Link className={classes.breadcrumbLink} to="/app/product/categories/"><Typography color="textPrimary">Product Categories</Typography></Link>
        <Typography color="textPrimary">{collection && collection[0] ? collection[0]?.Categories?.Title : 'Loading'}</Typography>
      </Breadcrumbs>

      {!collection && !showNoCategory && <CircularProgress />}

      <Box mb={2}>
        <Typography component="h1" variant="h4">
          {/* {collection[0]?.Categories?.Title} */}
          {location?.state?.collectionTitle}
        </Typography>
      </Box>

      {showNoCategory && <Alert className={classes.marginBottom} severity="warning" color="warning">
        No category selected. Please select a category from the Product Categories page. <Link to="/app/product/categories/">Go to Product Categories</Link>
      </Alert>}

      {collection && collection.length === 0 &&

        <Alert severity="warning" color="warning">
          No products in this collection right now. Check back in future.
        </Alert>
      }

      {collection && collection[0] && <>
        <Box mb={2}>
          <Typography component="h2" variant="body2">
            {(categories && categories.data) ? categories && categories.data && categories.data.find(a => a.Id == collection[0]?.Categories?.Id)?.Description : 'Loading...'}
          </Typography>
        </Box>

        {didJustAddToCart && <Alert className={classes.marginBottom} severity="success" color="success">
          Product added, see the top menu for the Shopping Cart.
        </Alert>}




        <Grid container spacing={2}>
          {userProfile && collection && collection
            .map(product =>
              <Grid key={product.id} item xs={12} sm={6} md={3}>
                <Card
                  className={classes.root}
                >
                  <CardActionArea onClick={() =>
                    navigate("/app/product/categories/products/detail/", {
                      state: { product, collectionName: product.Categories.Title, collectionId: product.Categories.Id },
                    })
                  }>
                    <CardMedia
                      className={classes.media}
                      image={product.Image ? convertImageFromNocodb(product.Image, 'tr:h-200,cm-pad_resize,bg-FFFFFF') ?? '/no-product.jpg' : '/no-product.jpg'}
                      title={product.Title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2" style={{ maxHeight: 100, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {product.Title}
                      </Typography>
                      {/* <Typography variant="body2" color="textSecondary" component="p">
                                            {product.description}
                                        </Typography> */}

                      <Typography gutterBottom variant="h6" component="b">
                        {roundCashFormat(getPriceBasedOnRoleNocodb({ roles: userProfile.roles, product }))}

                      </Typography>

                      {/* <Typography variant="subtitle1" color="textSecondary" component="p">
                        Stock: {getStockBasedOnProfileLocation({ location: userProfile?.warehouse, product })}
                      </Typography> */}
                      <Typography variant="subtitle1" color="textSecondary" component="p">
                        <b>JHB:</b> {product['JHB Stock']} <b>CPT:</b> {product['CPT Stock']}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>

                    <Button size="small" color="primary"
                      onClick={() =>
                        navigate("/app/product/categories/products/detail/", {
                          state: { product, collectionName: product.Categories.Title, collectionId: product.Categories.Id },
                        })
                      }
                    >
                      Details
                    </Button>


                    <Button size="small" color="primary"
                      onClick={() => {
                        const productToAdd = { id: product.id, product, name: product.Title, price: getPriceBasedOnRoleNocodb({ roles: userProfile.roles, product }), stock: parseInt(product['JHB Stock']), code: product.Code, quantity: 1 }
                        dispatch(addToCurrentOrder(productToAdd))
                        setDidJustAddToCart(true)
                      }
                      }
                      // disabled={getStockBasedOnProfileLocation({ location: userProfile?.warehouse, product }) <= 0}
                    >
                      Add to Cart
                    </Button>
                  </CardActions>
                </Card></Grid>)}
        </Grid>

      </>}


      {/* {(!publicStock || !publicStock.data) && <CircularProgress />}
      {publicStock &&
        publicStock.data &&
        uniqBy(publicStock.data.filter(a=>a.show), "category").map(product => (
          <Box mb={2} key={product.category}>
            <Box mb={1}>
              <Typography component="h2" variant="h5">
                {product.category}
              </Typography>
            </Box>
            <Table
              className={classes.table}
              aria-label={`${product.category} table`}
            >
              <TableBody>
                {publicStock &&
                  publicStock.data.filter(a=>a.show)
                    .filter(a => a.category === product.category)
                    .map(aProduct => (
                      <TableRow key={aProduct.id}>
                        <TableCell component="th" scope="row">
                          {aProduct.name}
                        </TableCell>
                        <TableCell>{aProduct.stock}</TableCell>
                        <TableCell align="right">
                          <Button
                            size="small"
                            color="primary"
                            onClick={() =>
                              navigate("/app/product/detail", {
                                state: aProduct,
                              })
                            }
                          >
                            More
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </Box>
        ))} */}
    </Container>
  )
}

export default AppProductsList
