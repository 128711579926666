import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, prefetchPathname, navigate } from "gatsby"
import Img from "gatsby-image"
import { Formik, Form, Field, ErrorMessage } from "formik"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import { getValidationSchemaVeichi, getValidationSchemaIrrigation } from "../validations"
import Autocomplete from "@material-ui/lab/Autocomplete"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Switch from '@material-ui/core/Switch';
import {
  getTowns,
  addAudit,
  getVeichiDrivesList,
  veichiCalculation as doVeichiCalculation,
  finishVeichiCalculation,
  setIrrigationInput,
  setIrrigationResult
} from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { canAccessSizing } from "../../utils/checkRoles"
import { irrigation } from '../pumps/veichi'
import domtoimage from "dom-to-image"
import Box from "@material-ui/core/Box"
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar'
import times from 'lodash/times'
import { getMonthFromNumber, getMonthSmallFromNumber, roundCashFormat } from "../../utils/common"
import sum from 'lodash/sum'
import { Table, TableRow, TableCell, TableHead, TableBody, Checkbox } from "@material-ui/core"
import loadable from '@loadable/component'
import roundTo from 'round-to'

const ViechiPdf = loadable.lib(() => import('./veichiPdf'))

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      dimensionsImage: file(relativePath: { eq: "veichi.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={data.dimensionsImage.childImageSharp.fixed} />
}

const MyResponsiveBar = ({ data, loaded }) => {
  const ratio = data[0].percent / data[0].flow
  
  return (<>
  <ResponsiveBar
    data={data}
    keys={['flow']}
    indexBy="month"
    animate={false}
    margin={{ top: 20, right: 100, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={['#00aeef']}
    enableLabel={false}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      // legend: '%',
      legendPosition: 'middle',
      legendOffset: 40,
      // tickValues: [0, 10, 20, 30, 40, 50, 60, 70, 90, 100],
      // tickValues: ['100%']
      format: value => {
        return `${Math.round(value*ratio)}%`
      }

    }}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Month',
      legendPosition: 'middle',
      legendOffset: 32
    }}
    // axisLeft={{
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   legend: 'm3/h',
    //   legendPosition: 'middle',
    //   legendOffset: -40
    // }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    layers={[
      'grid', 'axes', 'bars', 'markers', 'legends', 'annotations',
      () => {
        loaded()
        return null
      }
    ]}
  />
  </>
)}

const MyResponsiveLine = ({ data, loaded }) => (
  <ResponsiveLine
    curve="monotoneX"
    data={data}
    margin={{ top: 30, right: 110, bottom: 50, left: 60 }}
    xScale={{ type: 'point' }}
    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
    enablePoints={false}
    animate={false}
    // yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
    // yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Time',
      legendOffset: 36,
      legendPosition: 'middle'
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '%',
      legendOffset: -40,
      legendPosition: 'middle'
    }}
    // colors={{ scheme: 'category10',  }}
    colors={{ datum: 'color' }}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabelYOffset={-12}
    useMesh={true}
    layers={[
      'grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends',
      () => {
        loaded()
        return null
      }
    ]}
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
  />
)




const IrrigationForm = ({ initialValues, saveResult, submitting, loading }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))
  // const [manual, setManual] = useState(false)

  return (
    <Formik
      validationSchema={getValidationSchemaIrrigation}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        saveResult(values)
        actions.setSubmitting(false)
      }}
    >
      {({ errors, setFieldTouched, setFieldValue, isSubmitting, values }) => (
        <Form>
          <Grid container spacing={matches ? 2 : 0}>
            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="taxRate"
              >
                {({ field, form }) => (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="taxRate">
                      Tax Rate (%)
                    </InputLabel>
                    <OutlinedInput
                      labelWidth={200}
                      id="taxRate"
                      placeholder="27"
                      type="number"
                      {...field}
                      error={
                        form.errors[field.name] && form.touched[field.name]
                          ? true
                          : false
                      }
                    />
                    <ErrorMessage name="taxRate">
                      {msg => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="systemCost"
              >
                {({ field, form }) => (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="systemCost">
                      System Cost (R)
                    </InputLabel>
                    <OutlinedInput
                      labelWidth={200}
                      id="systemCost"
                      placeholder="1020000"
                      type="number"
                      {...field}
                      error={
                        form.errors[field.name] && form.touched[field.name]
                          ? true
                          : false
                      }
                    />
                    <ErrorMessage name="systemCost">
                      {msg => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="deposit"
              >
                {({ field, form }) => (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="deposit">
                      Deposit (%)
                    </InputLabel>
                    <OutlinedInput
                      labelWidth={200}
                      id="deposit"
                      placeholder="10"
                      type="number"
                      {...field}
                      error={
                        form.errors[field.name] && form.touched[field.name]
                          ? true
                          : false
                      }
                    />
                    <ErrorMessage name="deposit">
                      {msg => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="loanPeriod"
              >
                {({ field, form }) => (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="loanPeriod">
                      Loan Period (Year)
                    </InputLabel>
                    <OutlinedInput
                      labelWidth={200}
                      id="loanPeriod"
                      placeholder="10"
                      type="number"
                      {...field}
                      error={
                        form.errors[field.name] && form.touched[field.name]
                          ? true
                          : false
                      }
                    />
                    <ErrorMessage name="loanPeriod">
                      {msg => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="interestRate"
              >
                {({ field, form }) => (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="interestRate">
                      Interest Rate (%)
                    </InputLabel>
                    <OutlinedInput
                      labelWidth={200}
                      id="interestRate"
                      placeholder="7.5"
                      type="number"
                      {...field}
                      error={
                        form.errors[field.name] && form.touched[field.name]
                          ? true
                          : false
                      }
                    />
                    <ErrorMessage name="interestRate">
                      {msg => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Grid>


            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="electricityIncrease5Yr"
              >
                {({ field, form }) => (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="electricityIncrease5Yr">
                      Electricity Increase 5 Yr (%)
                    </InputLabel>
                    <OutlinedInput
                      labelWidth={200}
                      id="electricityIncrease5Yr"
                      placeholder="8"
                      type="number"
                      {...field}
                      error={
                        form.errors[field.name] && form.touched[field.name]
                          ? true
                          : false
                      }
                    />
                    <ErrorMessage name="electricityIncrease5Yr">
                      {msg => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="electricityIncrease6Yr"
              >
                {({ field, form }) => (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="electricityIncrease6Yr">
                      Electricity Increase 6 Yr (%)
                    </InputLabel>
                    <OutlinedInput
                      labelWidth={200}
                      id="electricityIncrease6Yr"
                      placeholder="6"
                      type="number"
                      {...field}
                      error={
                        form.errors[field.name] && form.touched[field.name]
                          ? true
                          : false
                      }
                    />
                    <ErrorMessage name="electricityIncrease6Yr">
                      {msg => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                type="number"
                inputmode="numeric"
                name="averageEnergyCost"
              >
                {({ field, form }) => (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="averageEnergyCost">
                      Average Energy Cost per kWh (R)
                    </InputLabel>
                    <OutlinedInput
                      labelWidth={250}
                      id="averageEnergyCost"
                      placeholder="1.1"
                      type="number"
                      {...field}
                      error={
                        form.errors[field.name] && form.touched[field.name]
                          ? true
                          : false
                      }
                    />
                    <ErrorMessage name="averageEnergyCost">
                      {msg => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Grid>


            <Grid item xs={6} sm={6}>

              <Typography gutterBottom>Monthly Usage (kWh)</Typography>

              {times(12).map((a) => (

                <Field
                  key={a}
                  type="number"
                  inputmode="numeric"
                  name={`monthlyConsumptionCost[${a}]`}
                >
                  {({ field, form }) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel htmlFor={`monthlyConsumptionCost[${a}]`}>
                        {getMonthFromNumber(a)}
                      </InputLabel>
                      <OutlinedInput
                        type="number"
                        labelWidth={200}
                        id={`monthlyConsumptionCost[${a}]`}
                        placeholder="0"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                      />
                      <ErrorMessage name={`monthlyConsumptionCost[${a}]`}>
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>))}
            </Grid>

          </Grid>

          {sum(values.monthlyConsumptionCost) <= 0 && <Typography style={{ color: 'red' }} variant="body2" gutterBottom>Monthly Usage requires at least 1 value.</Typography>}


          {/* {console.log("ferrors", errors)} */}
          <br />

          <Button
            color="primary"
            variant="contained"
            disabled={
              loading ||
              isSubmitting ||
              Object.keys(errors).length > 0 ||
              submitting ||
              sum(values.monthlyConsumptionCost) <= 0
            }
            type="submit"
          >
            Submit
          </Button>
          {(submitting || loading) && <CircularProgress />}
        </Form>
      )}
    </Formik>
  )
}


const defaultValues = {
  customer: "",
  phone: "",
  email: "",
  voltage: 380,
  powerRating: 6,
  flow: 0,
  panelWatt: 555,
  panelVMP: 40.99,
  panelVOC: 49.72,
  panelMake: 'Jinko',
  manual: false,
  drive: null,
  numberOfStrings: 2,
  panelsPerString: 10,
  hybrid: false,
  // town: towns.data.find(a => a.name === "Unknown"),
}

const irrigationDefault = {
  taxRate: 27,
  systemCost: 102000,
  deposit: 10,
  loanPeriod: 10,
  interestRate: 7.5,
  electricityIncrease5Yr: 8.0,
  electricityIncrease6Yr: 6.0,
  averageEnergyCost: 1.1,
  monthlyConsumptionCost: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
}

const CustomerForm = ({ initialValues, saveResult, submitting, loading }) => {
  const { towns, irrigationInput, viechiDrives } = useSelector(state => state.db)
  const dispatch = useDispatch()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))
  // const [manual, setManual] = useState(false)
  const [irrigationOpen, setIrrigationOpen] = useState(false)
  const [irrigationInfo, setIrrigationInfo] = useState(null)

  useEffect(() => {
    dispatch(getVeichiDrivesList())
  }, [])

  return (
    <>
      <Dialog
        key="irrigation"
        // fullScreen
        fullWidth
        maxWidth="md"
        open={irrigationOpen}
        onClose={() => setIrrigationOpen(false)}
      >
        <DialogTitle>Irrigation Details</DialogTitle>
        <Box margin={2}>
          <Typography variant="body1">You can add financial details about your irrigation setup to find out payback details</Typography>
        </Box>
        <Box margin={2}>
          <IrrigationForm initialValues={{ ...irrigationDefault, ...irrigationInput?.data }} saveResult={(vals) => {
            setIrrigationInfo(vals)
            dispatch(setIrrigationInput({ loading: false, msg: null, error: null, data: vals }))
            setIrrigationOpen(false)
          }} />

        </Box>
      </Dialog>


      <Formik
        validationSchema={getValidationSchemaVeichi}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          saveResult({ ...values, irrigationInfo })
          actions.setSubmitting(false)
        }}
      >
        {({ errors, setFieldTouched, setFieldValue, isSubmitting, values }) => (
          <Form>
            <Grid container spacing={matches ? 2 : 0}>
              <Grid item xs={12} sm={6}>
                <Field
                  type="string"
                  name="customer"
                >
                  {({ field, form }) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel htmlFor="customer">Customer</InputLabel>
                      <OutlinedInput
                        labelWidth={70}
                        id="customer"
                        placeholder="Customer"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                      />
                      <ErrorMessage name="customer">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  type="string"
                  name="phone"
                  inputmode="numeric"
                >
                  {({ field, form }) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel htmlFor="phone">Phone Number</InputLabel>
                      <OutlinedInput
                        labelWidth={110}
                        id="phone"
                        placeholder="+27 081 555 555"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                      />
                      <ErrorMessage name="phone">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  type="email"
                  name="email"
                >
                  {({ field, form }) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel htmlFor="email">Email Address</InputLabel>
                      <OutlinedInput
                        labelWidth={110}
                        id="email"
                        placeholder="Email"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                      />
                      <ErrorMessage name="email">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Grid>

              <Grid item xs={12} sm={6}>
                {towns && towns.data && towns.data.length < 2 && (
                  <>
                    <span>Connection Error please click below</span>
                    <br />
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(getTowns())
                        dispatch(getVeichiDrivesList())
                      }}
                    >
                      Get Towns Again
                    </Button>
                  </>
                )}
                {towns && towns.data && towns.data.length > 2 && (
                  <Field
                    type="string"
                    name="town"
                  >
                    {({ field }) => (
                      <FormControl fullWidth variant="outlined" margin="normal">
                        <Autocomplete
                          value={field.value} // !=='unknown'?field.value:towns.data.find(a => 'aqU3HWPX3kRyHJZvQISY' === a.id)}
                          onChange={(event, newValue) => {
                            setFieldTouched()
                            setFieldValue(field.name, newValue)
                          }}
                          options={towns.data}
                          getOptionLabel={
                            option => option.name
                            // ? option.name
                            // : 'apples' //state.townsResult.find(a => option === a.value).name
                          } // option.name?option.name:towns().find(a=>option===a.value)}
                          //   style={{ width: 300 }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Closest Town"
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "not-town",
                              }}
                            />
                          )}
                        />
                        <ErrorMessage name="town">
                          {msg => <FormHelperText>{msg}</FormHelperText>}
                        </ErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  type="number"
                  name="voltage"
                >{({ field }) => (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="voltage">
                      Voltage
                    </InputLabel>

                    <Select
                      labelWidth={150}
                      id="voltage"
                      type="select"
                      name="voltage"
                      {...field}
                    >
                      <MenuItem value={380}>3 Phase (380V)</MenuItem>
                      <MenuItem value={240}>Single Phase (240V)</MenuItem>
                    </Select>

                    <ErrorMessage name="voltage">
                      {msg => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>
                )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  type="number"
                  inputmode="numeric"
                  name="powerRating"
                >
                  {({ field, form }) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel htmlFor="powerRating">
                        Pump Power rating (kW)
                      </InputLabel>
                      <OutlinedInput
                        labelWidth={200}
                        id="powerRating"
                        placeholder="30"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                      />
                      <ErrorMessage name="powerRating">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  type="number"
                  inputmode="numeric"
                  name="flow"
                >
                  {({ field, form }) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel htmlFor="flow">
                        Flow at TDH (m<sup>3</sup>/h)
                      </InputLabel>
                      <OutlinedInput
                        labelWidth={200}
                        id="flow"
                        placeholder="0"
                        type="number"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                      />
                      <ErrorMessage name="flow">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  type="checkbox"
                  name="hybrid"
                >
                  {({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(event) => setFieldValue(field.name, event.target.checked)}
                          name="Hybrid System (Grid and Solar Power)"
                          color="primary"
                        />
                      }
                      label="Hybrid System (Grid and Solar Power)"
                    />)}</Field>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  type="checkbox"
                  name="manual"
                >
                  {({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={field.value}
                          onChange={(event) => setFieldValue(field.name, event.target.checked)}
                          name="Manual Sizing"
                          color="primary"
                        />
                      }
                      label="Manual Sizing"
                    />)}</Field>
              </Grid>
              {values.manual && viechiDrives && viechiDrives.data && viechiDrives.data.length>1 && <>
                <Grid item xs={12} sm={6}>
                  <Field
                    type="text"
                    name="drive"
                  >{({ field }) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel htmlFor="drive">
                        Drive
                      </InputLabel>
                      <Select
                        labelWidth={80}
                        id="voltage"
                        type="select"
                        name="drive"
                        {...field}
                      >
                        {viechiDrives.data.map(a => <MenuItem key={a.name} value={a}>{a.name}</MenuItem>)}
                      </Select>

                      <ErrorMessage name="drive">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    type="number"
                    inputmode="numeric"
                    name="numberOfStrings"
                  >
                    {({ field, form }) => (
                      <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel htmlFor="numberOfStrings">
                          Number of Strings
                        </InputLabel>
                        <OutlinedInput
                          labelWidth={180}
                          id="numberOfStrings"
                          placeholder="20"
                          {...field}
                          error={
                            form.errors[field.name] && form.touched[field.name]
                              ? true
                              : false
                          }
                        />
                        <ErrorMessage name="numberOfStrings">
                          {msg => <FormHelperText>{msg}</FormHelperText>}
                        </ErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    type="number"
                    inputmode="numeric"
                    name="panelsPerString"
                  >
                    {({ field, form }) => (
                      <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel htmlFor="panelsPerString">
                          Panels Per String
                        </InputLabel>
                        <OutlinedInput
                          labelWidth={180}
                          id="panelsPerString"
                          placeholder="10"
                          {...field}
                          error={
                            form.errors[field.name] && form.touched[field.name]
                              ? true
                              : false
                          }
                        />
                        <ErrorMessage name="panelsPerString">
                          {msg => <FormHelperText>{msg}</FormHelperText>}
                        </ErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              </>}
              <Grid item xs={12}>
                <Typography>Solar Panel Details</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  type="number"
                  inputmode="numeric"
                  name="panelWatt"
                >
                  {({ field, form }) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel htmlFor="panelWatt">
                        Watt
                      </InputLabel>
                      <OutlinedInput
                        labelWidth={50}
                        id="panelWatt"
                        placeholder="375"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                      />
                      <ErrorMessage name="panelWatt">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  type="number"
                  inputmode="numeric"
                  name="panelVMP"
                >
                  {({ field, form }) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel htmlFor="panelVMP">
                        VMP
                      </InputLabel>
                      <OutlinedInput
                        labelWidth={50}
                        id="panelVMP"
                        placeholder="34.5"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                      />
                      <ErrorMessage name="panelVMP">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  type="number"
                  inputmode="numeric"
                  name="panelVOC"
                >
                  {({ field, form }) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel htmlFor="panelVOC">
                        VOC
                      </InputLabel>
                      <OutlinedInput
                        labelWidth={50}
                        id="panelVOC"
                        placeholder="41.12"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                      />
                      <ErrorMessage name="panelVOC">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  type="text"
                  name="panelMake"
                >
                  {({ field, form }) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel htmlFor="panelMake">
                        Make
                      </InputLabel>
                      <OutlinedInput
                        labelWidth={50}
                        id="panelMake"
                        placeholder="Jinko"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                      />
                      <ErrorMessage name="panelMake">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Grid>
            </Grid>
            <br />
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setIrrigationOpen(true)}
            >
              Add Irrigation Payback Input
            </Button>{" "}
            {irrigationInput && irrigationInput.data && <Button
              color="primary"
              style={{color: "#FF0000", borderColor: "#FF0000"}}
              variant="outlined"
              onClick={() =>  {
                setIrrigationInfo(null)
                // why are the 2 i need to clear lol
                dispatch(setIrrigationInput({ loading: false, msg: null, error: null, data: null }))
                dispatch(setIrrigationResult({ loading: false, msg: null, error: null, data: null }))
              }}
            >
              Remove Irrigation Input
            </Button>}
            <br />
            {/* {console.log("ferrors", errors)} */}
            {/* {errors && errors.totalWellDepth &&         
          <Typography component="p" variant="body1" style={{color: 'red'}}>
          {errors.totalWellDepth}
        </Typography>} */}
            <br />

            <Button
              color="primary"
              variant="contained"
              disabled={
                loading ||
                isSubmitting ||
                values.manual && values.drive === null ||
                Object.keys(errors).length > 0 ||
                submitting ||
                (towns && towns.data.length < 2) ||
                !towns
              }
              type="submit"
            >
              Calculate
            </Button>
            {(submitting || loading) && <CircularProgress />}
          </Form>
        )}
      </Formik>
    </>
  )
}

const MonthlyElectricityUsage = ({ data, loaded }) => (
  <ResponsiveBar
    data={data}
    keys={['kwh']}
    indexBy="month"
    animate={false}
    margin={{ top: 20, right: 100, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={['#00aeef']}
    enableLabel={false}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Month',
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'kWh',
      legendPosition: 'middle',
      legendOffset: -50
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    layers={[
      'grid', 'axes', 'bars', 'markers', 'legends', 'annotations',
      () => {
        loaded()
        return null
      }
    ]}
  // legends={[
  //     {
  //         dataFrom: 'keys',
  //         anchor: 'bottom-right',
  //         direction: 'column',
  //         justify: false,
  //         translateX: 120,
  //         translateY: 0,
  //         itemsSpacing: 2,
  //         itemWidth: 100,
  //         itemHeight: 20,
  //         itemDirection: 'left-to-right',
  //         itemOpacity: 0.85,
  //         symbolSize: 20,
  //         effects: [
  //             {
  //                 on: 'hover',
  //                 style: {
  //                     itemOpacity: 1
  //                 }
  //             }
  //         ]
  //     }
  // ]}
  />
)

const DaylightPumpingHoursGraph = ({ data, loaded }) => (
  <ResponsiveLine
    curve="monotoneX"
    data={data}
    margin={{ top: 30, right: 110, bottom: 50, left: 60 }}
    xScale={{ type: 'point' }}
    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
    enablePoints={false}
    animate={false}
    // yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
    // yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Time',
      legendOffset: 36,
      legendPosition: 'middle'
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '%',
      legendOffset: -40,
      legendPosition: 'middle'
    }}
    colors={{ scheme: 'category10', }}
    // colors={{ datum: 'color' }}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabelYOffset={-12}
    useMesh={true}
    layers={[
      'grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends',
      () => {
        loaded()
        return null
      }
    ]}
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
  />
)

const SavingsPotentialGraph = ({ data, loaded }) => (
  <ResponsiveBar
    data={data}
    keys={['Potential Savings', 'Actual Savings']}
    groupMode="grouped"
    indexBy="month"
    animate={false}
    margin={{ top: 20, right: 120, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={['#00aeef', '#43eb34']}
    enableLabel={false}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Month',
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'R',
      legendPosition: 'middle',
      legendOffset: -50
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    layers={[
      'grid', 'axes', 'bars', 'markers', 'legends', 'annotations',
      () => {
        loaded()
        return null
      }
    ]}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 110,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
  />
)


const DailySystemPerformanceGraph = ({ data, loaded }) => (
  <ResponsiveLine
    curve="monotoneX"
    data={data}
    margin={{ top: 30, right: 130, bottom: 50, left: 60 }}
    xScale={{ type: 'point' }}
    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
    enablePoints={false}
    animate={false}
    // yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
    // yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Time',
      legendOffset: 36,
      legendPosition: 'middle'
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'kW',
      legendOffset: -40,
      legendPosition: 'middle'
    }}
    // colors={{ scheme: 'category10',  }}
    colors={{ datum: 'color' }}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabelYOffset={-12}
    useMesh={true}
    layers={[
      'grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends',
      () => {
        loaded()
        return null
      }
    ]}
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
  />
)


const IrrigationResult = ({ onLoaded }) => {
  const { irrigationResult } = useSelector(state => state.db)

  const [monthlyElectricityUsageGraph, setMonthlyElectricityUsageGraph] = useState(null)
  const [daylightPumpingHoursGraph, setDaylightPumpingHoursGraph] = useState(null)
  const [savingsPotentialGraph, setSavingsPotentialGraph] = useState(null)
  const [dailySystemPerformanceGraph, setDailySystemPerformanceGraph] = useState(null)

  useEffect(() => {
    onLoaded({ monthlyElectricityUsageGraph, daylightPumpingHoursGraph, savingsPotentialGraph, dailySystemPerformanceGraph })
  }, [monthlyElectricityUsageGraph, daylightPumpingHoursGraph, savingsPotentialGraph, dailySystemPerformanceGraph])


  // probably a more elegant way
  function blobToDataURL(blob, callback) {
    var a = new FileReader()
    a.onload = function (e) {
      callback(e.target.result)
    }
    a.readAsDataURL(blob)
  }


  function saveMonthlyElectricityUsageGraph() {
    setTimeout(() => {
      domtoimage
        .toBlob(document.getElementById("MonthlyElectricityUsageGraph"), {
          bgcolor: "#fff",
          height: 300,
          width: window.innerWidth < 500 ? window.innerWidth - 50 : 500,
          cacheBust: false,
        })
        .then(blob => {
          blobToDataURL(blob, function (dataurl) {
            setMonthlyElectricityUsageGraph(dataurl)
          })
        })
        .catch(error => {
          console.error("oops, something went wrong!", error)
        })
    }, 500)

  }

  function saveDaylightPumpingHoursGraph() {
    setTimeout(() => {
      domtoimage
        .toBlob(document.getElementById("DaylightPumpingHoursGraph"), {
          bgcolor: "#fff",
          height: 300,
          width: 650,
          cacheBust: false,
        })
        .then(blob => {
          blobToDataURL(blob, function (dataurl) {
            setDaylightPumpingHoursGraph(dataurl)
          })
        })
        .catch(error => {
          console.error("oops, something went wrong!", error)
        })
    }, 500)
  }

  function saveSavingsPotentialGraph() {
    setTimeout(() => {
      domtoimage
        .toBlob(document.getElementById("SavingsPotentialGraph"), {
          bgcolor: "#fff",
          height: 300,
          width: 650,
          cacheBust: false,
        })
        .then(blob => {
          blobToDataURL(blob, function (dataurl) {
            setSavingsPotentialGraph(dataurl)
          })
        })
        .catch(error => {
          console.error("oops, something went wrong!", error)
        })
    }, 500)
  }

  function saveDailySystemPerformanceGraph() {
    setTimeout(() => {
      domtoimage
        .toBlob(document.getElementById("DailySystemPerformanceGraph"), {
          bgcolor: "#fff",
          height: 300,
          width: 650,
          cacheBust: false,
        })
        .then(blob => {
          blobToDataURL(blob, function (dataurl) {
            setDailySystemPerformanceGraph(dataurl)
          })
        })
        .catch(error => {
          console.error("oops, something went wrong!", error)
        })
    }, 500)
  }


  if (irrigationResult && irrigationResult.data) {

    return <Box>
      <Typography component="h2" variant="h6" gutterBottom>Irrigation Payback Results</Typography>

      <Typography>Monthly Electricity Usage</Typography>

      <div style={{ height: '300px', width: '600px' }} id="MonthlyElectricityUsageGraph">

        <MonthlyElectricityUsage data={irrigationResult.data.monthsKwh.map((kwh, i) => {
          return { month: getMonthSmallFromNumber(i), kwh }
        })} loaded={() => saveMonthlyElectricityUsageGraph()} />
      </div>

      <Typography>Daily Pumping Hours</Typography>

      <div style={{ height: '300px', width: '600px' }} id="DaylightPumpingHoursGraph">
        <DaylightPumpingHoursGraph data={
          irrigationResult.data.monthsHourly.map((month, i) => {
            return {
              id: getMonthFromNumber(i),
              data: month.hourResults.map(a => ({ x: a.hour, y: a.pumpPercent * 100 }))
            }
          })} loaded={() => saveDaylightPumpingHoursGraph()} />
      </div>

      {/* DailySystemPerformanceGraph */}

      <Typography>Daily System Performance</Typography>

      <div style={{ height: '300px', width: '600px' }} id="DailySystemPerformanceGraph">
        <DailySystemPerformanceGraph data={[
          {
            id: 'Pump Output',
            color: '#43eb34',
            data: irrigationResult.data.hourlyTotals.map(a => ({ x: a.hour, y: a.pumpOutput }))
          },
          {
            id: 'Power From Solar',
            "color": "#ebe134",
            data: irrigationResult.data.hourlyTotals.map(a => ({ x: a.hour, y: a.powerFromSolar }))
          },
          {
            id: 'Power From Grid',
            color: '#eb3d34',
            data: irrigationResult.data.hourlyTotals.map(a => ({ x: a.hour, y: a.powerFromGrid }))
          }
        ]} loaded={() => saveDailySystemPerformanceGraph()} />
      </div>

      <Typography>Savings Potential</Typography>
      <div style={{ height: '300px', width: '600px' }} id="SavingsPotentialGraph">


        <SavingsPotentialGraph data={irrigationResult.data.monthsHourly.map((a, i) => {
          return { month: getMonthSmallFromNumber(i), 'Potential Savings': a.potentialSavings, 'Actual Savings': a.actualSavings }
        })} loaded={() => saveSavingsPotentialGraph()} />
      </div>

      <Typography variant="body1" gutterBottom>Loan Based Cashflow Analysis</Typography>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Loan Repayment</TableCell>
            <TableCell>Electricity Savings</TableCell>
            <TableCell>Tax Savings</TableCell>
            <TableCell>Annual Cashflow</TableCell>
            <TableCell>Cumulative Cashflow</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {irrigationResult.data.loanYears.map(a => <TableRow>
            <TableCell>{a.year}</TableCell>
            <TableCell style={{ background: a.loanRepayment < 0 ? '#eb3d34' : '#43eb34' }}>{roundCashFormat(a.loanRepayment)}</TableCell>
            <TableCell style={{ background: a.electricitySavings < 0 ? '#eb3d34' : '#43eb34' }}>{roundCashFormat(a.electricitySavings)}</TableCell>
            <TableCell style={{ background: a.taxSavings < 0 ? '#eb3d34' : '#43eb34' }}>{roundCashFormat(a.taxSavings)}</TableCell>
            <TableCell style={{ background: a.annualCashflow < 0 ? '#eb3d34' : '#43eb34' }}>{roundCashFormat(a.annualCashflow)}</TableCell>
            <TableCell style={{ background: a.cumulativeCashFlow < 0 ? '#eb3d34' : '#43eb34' }}>{roundCashFormat(a.cumulativeCashFlow)}</TableCell>

          </TableRow>)}
        </TableBody>
      </Table>

      <Box marginTop={2} marginBottom={2}>
        <Table size="small" style={{ maxWidth: '400px' }}>
          <TableRow>
            <TableCell>Solar kWh Generation Cost</TableCell>
            <TableCell>{roundCashFormat(irrigationResult.data.solarKwhGenerationCostLoan)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Break Even</TableCell>
            <TableCell>{irrigationResult.data.breakEvenYearLoan} years and {irrigationResult.data.breakEvenMonthsLoan} months</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Net Present Value</TableCell>
            <TableCell style={{ background: irrigationResult.data.netPresentValLoan < 0 ? '#eb3d34' : '#43eb34' }}>{roundCashFormat(irrigationResult.data.netPresentValLoan)}</TableCell>
          </TableRow>
        </Table>
      </Box>

      <Typography variant="body1" style={{ textWeight: 'bold' }} gutterBottom>Cash Based Cashflow Analysis</Typography>


      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Money Outlay</TableCell>
            <TableCell>Electricity Savings</TableCell>
            <TableCell>Tax Savings</TableCell>
            <TableCell>Annual Cashflow</TableCell>
            <TableCell>Cumulative Cashflow</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {irrigationResult.data.cashYears.map(a => <TableRow>
            <TableCell>{a.year}</TableCell>
            <TableCell style={{ background: a.monthlyOutlay < 0 ? '#eb3d34' : '#43eb34' }}>{roundCashFormat(a.monthlyOutlay)}</TableCell>
            <TableCell style={{ background: a.electricitySavings < 0 ? '#eb3d34' : '#43eb34' }}>{roundCashFormat(a.electricitySavings)}</TableCell>
            <TableCell style={{ background: a.taxSavings < 0 ? '#eb3d34' : '#43eb34' }}>{roundCashFormat(a.taxSavings)}</TableCell>
            <TableCell style={{ background: a.annualCashflow < 0 ? '#eb3d34' : '#43eb34' }}>{roundCashFormat(a.annualCashflow)}</TableCell>
            <TableCell style={{ background: a.cumulativeCashFlow < 0 ? '#eb3d34' : '#43eb34' }}>{roundCashFormat(a.cumulativeCashFlow)}</TableCell>

          </TableRow>)}
        </TableBody>
      </Table>

      <Box marginTop={2} marginBottom={2}>
        <Table size="small" style={{ maxWidth: '400px' }}>
        <TableRow>
            <TableCell>Solar kWh Generation Cost</TableCell>
            <TableCell>{roundCashFormat(irrigationResult.data.solarKwhGenerationCostCash)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Break Even</TableCell>
            <TableCell>{irrigationResult.data.breakEvenYearCash} years and {irrigationResult.data.breakEvenMonthsCash} months</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Net Present Value</TableCell>
            <TableCell style={{ background: irrigationResult.data.netPresentValCash < 0 ? '#eb3d34' : '#43eb34' }}>{roundCashFormat(irrigationResult.data.netPresentValCash)}</TableCell>
          </TableRow>
        </Table>
      </Box>


    </Box>
  } else {
    return <></>
  }
}

const months = [
  { x: "Jan".toString(), y: 0 },
  { x: "Feb".toString(), y: 0 },
  { x: "Mar".toString(), y: 0 },
  { x: "Apr".toString(), y: 0 },
  { x: "May".toString(), y: 0 },
  { x: "Jun".toString(), y: 0 },
  { x: "Jul".toString(), y: 0 },
  { x: "Aug".toString(), y: 0 },
  { x: "Sep".toString(), y: 0 },
  { x: "Oct".toString(), y: 0 },
  { x: "Nov".toString(), y: 0 },
  { x: "Dec".toString(), y: 0 },
]

const hourlyYield = [
  { hour: "06:00", percent: 0 },
  { hour: "07:00", percent: 3 },
  { hour: "08:00", percent: 32 },
  { hour: "09:00", percent: 50 },
  { hour: "10:00", percent: 75 },
  { hour: "11:00", percent: 90 },
  { hour: "12:00", percent: 100 },
  { hour: "13:00", percent: 99 },
  { hour: "14:00", percent: 85 },
  { hour: "15:00", percent: 65 },
  { hour: "16:00", percent: 30 },
  { hour: "17:00", percent: 1 },
  { hour: "18:00", percent: 0 },
]

const generateGraphData = (monthFlow, monthPercent) => {
  return monthFlow.map((flow, i) => {
    return { month: months[i].x, flow: roundTo(flow/1000, 0), percent: monthPercent[i] }
  })
}

function ResultDisplayDialog({ show, result, onClose }) {
  const { irrigationInput, irrigationResult, userProfile } = useSelector(state => state.db)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(show)
  const [dailyYieldChart, setDailyYieldChart] = useState(null)
  const [monthlyYieldChart, setMonthlyYieldChart] = useState(null)

  const [irrigationImages, setIrrigationImages] = useState(null)

  const [irrigationImagesLoaded, setIrrigationImagesLoaded] = useState(true) // so we want default to be loaded. Only do stuff if they add irrigation data

  // this is a mess 
  useEffect(()=>{
    if((irrigationInput && irrigationInput.data) && irrigationImages && (irrigationImages.monthlyElectricityUsageGraph && irrigationImages.dailySystemPerformanceGraph && irrigationImages.savingsPotentialGraph && irrigationImages.daylightPumpingHoursGraph)){
      setIrrigationImagesLoaded(true)
    }else if(irrigationInput && irrigationInput.data){
      // so there is irrigation data so its not loaded yet
      setIrrigationImagesLoaded(false)
    }
  },[irrigationInput, irrigationImages])

  // probably a more elegant way
  function blobToDataURL(blob, callback) {
    var a = new FileReader()
    a.onload = function (e) {
      callback(e.target.result)
    }
    a.readAsDataURL(blob)
  }


  function saveMonthlyYieldChart() {
    setTimeout(() => {
      domtoimage
        .toBlob(document.getElementById("monthlyYieldChart"), {
          bgcolor: "#fff",
          height: 300,
          width: 600,
          cacheBust: false,
        })
        .then(blob => {
          blobToDataURL(blob, function (dataurl) {
            setMonthlyYieldChart(dataurl)
          })
        })
        .catch(error => {
          console.error("oops, something went wrong!", error)
        })
    }, 500)

  }

  function saveDailyYieldChart() {
    setTimeout(() => {
      domtoimage
        .toBlob(document.getElementById("dailyYieldChart"), {
          bgcolor: "#fff",
          height: 300,
          width: 650,
          cacheBust: false,
        })
        .then(blob => {
          blobToDataURL(blob, function (dataurl) {
            setDailyYieldChart(dataurl)
          })
        })
        .catch(error => {
          console.error("oops, something went wrong!", error)
        })
    }, 500)
  }


  // useEffect(() => {
  //   // setTimeout(() => saveImage(), 300)
  //   prefetchPathname("/pdfveichi/")

  // }, [result])

  useEffect(() => {
    if (irrigationInput && irrigationInput.data) {
      if (result && result.pumpResult && irrigationInput && irrigationInput.data) {

        const resultTwo = irrigation({
          powerRating: result.pumpResult.powerRating, driveResult: result.pumpResult.flows,
          taxRate: result.irrigationInputs.taxRate,
          systemCost: result.irrigationInputs.systemCost,
          deposit: result.irrigationInputs.deposit,
          loanPeriod: result.irrigationInputs.loanPeriod,
          interestRate: result.irrigationInputs.interestRate,
          electricityIncrease5Yr: result.irrigationInputs.electricityIncrease5Yr,
          electricityIncrease6Yr: result.irrigationInputs.electricityIncrease6Yr,
          averageEnergyCost: result.irrigationInputs.averageEnergyCost,
          monthlyConsumptionCost: result.irrigationInputs.monthlyConsumptionCost,
        })


        dispatch(setIrrigationResult({
          loading: false,
          msg: null,
          error: null,
          data: resultTwo,
        }))

      }
    }

  }, [irrigationInput])

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  return (
    <React.Fragment>
      <Dialog
        key="result-d"
        // fullScreen
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="result-display-dialog"
      >
        <DialogTitle id="result-dialogitle">Sizing Details</DialogTitle>
        {result.pumpResult.error && <DialogContent>
          <Typography component="h2" variant="h6">
            Cannot find a drive that will work. Contact us at support@cedarsolar.com for a custom sizing on large or unusual projects.
          </Typography>
        </DialogContent>
        }
        {!result.pumpResult.error && <DialogContent>
          {!result.pumpResult.manual && <>
            <Typography component="h2" variant="h6">
              Recommended Drive: {result.pumpResult.drive.name}
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography component="p" variant="body1">
                Number of Strings:{" "}
                {result.pumpResult.panels.stringNeededRounded}
              </Typography>
              <div style={{ width: "20px" }}></div>
              <Typography component="p" variant="body1">
                Total Number of Panels: {result.pumpResult.panels.totalPanels}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography component="p" variant="body1">
                Panels per String:{" "}
                {result.pumpResult.panels.panelsPerString}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography component="p" variant="body1">
                Sizing Factor:{" "}
                {result.pumpResult.sizingFactor}
              </Typography>
            </div>
          </>}
          {result.pumpResult.manual && <>
            <Typography component="h2" variant="h6">
              Drive (manual selection): {result.pumpResult.drive.name}
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography component="p" variant="body1">
                Number of Strings:{" "}
                {result.pumpResult.panels.stringNeededRounded}
              </Typography>
              <div style={{ width: "20px" }}></div>
              <Typography component="p" variant="body1">
                Total Number of Panels: {result.pumpResult.panels.totalPanels}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography component="p" variant="body1">
                Panels per String:{" "}
                {result.pumpResult.panels.panelsPerString}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography component="p" variant="body1">
                Sizing Factor:{" "}
                {result.pumpResult.sizingFactor}
              </Typography>
            </div>
          </>}
          {result.pumpResult.warnings && result.pumpResult.warnings.map(a => <Typography key={a} component="p" variant="body1" style={{ color: 'red', marginTop: '20px' }}>
            {a}
          </Typography>)}

          <br />
          <div style={{ display: "flex" }}>
            <Typography component="p" variant="subtitle2">
              Panel Details
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography component="p" variant="body1">
              Watt:{" "}
              {result.pumpResult.panels.watt}
            </Typography>
            <div style={{ width: "20px" }}></div>
            <Typography component="p" variant="body1">
              VMP: {result.pumpResult.panels.vmp}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography component="p" variant="body1">
              VOC:{" "}
              {result.pumpResult.panels.voc}
            </Typography>
            <div style={{ width: "20px" }}></div>
            <Typography component="p" variant="body1">
              Make: {result.pumpResult.panels.make}
            </Typography>
          </div>
          <br />
          <Typography component="h2" variant="h6">
            Average Daily Yield (m<sup>3</sup>):{" "}
            {result.pumpResult.flows.averageDriveFlowPerDayAtTownCubicLiters}
          </Typography>

          <Typography component="h2" variant="subtitle1">
            Average Daily Yield
          </Typography>
          <div style={{ height: '300px', width: '600px' }} id="dailyYieldChart">
            <MyResponsiveLine
              loaded={() => saveDailyYieldChart()}
              data={[
                {
                  id: 'Drive Output',
                  color: '#43eb34',
                  data: result.pumpResult.flows.averageDriveFlowPerHour.map(
                    (a, i) => ({ x: hourlyYield[i].hour, y: (a.flow / result.pumpResult.thd) * 100 })
                  )
                },
                {
                  "id": "Sunshine",
                  "color": "#ebe134",
                  data: result.pumpResult.flows.averageSunFlowPerHour.map(
                    (a, i) => ({ x: hourlyYield[i].hour, y: (a.flow / result.pumpResult.thd) * 100 })
                  )
                },
                {
                  id: 'Sizing Factor',
                  color: '#eb3d34',
                  data: result.pumpResult.flows.averageSizingFactorFlowPerHour.map(
                    (a, i) => ({ x: hourlyYield[i].hour, y: (a.flow / result.pumpResult.thd) * 100 })
                  )
                },


              ]} />
          </div>

          <br />
          <Typography component="h2" variant="subtitle1">
            Average Daily Yield per Month
          </Typography>
          <div id="monthlyYieldChart" style={{ height: '300px', width: '600px' }}>
            <MyResponsiveBar
              loaded={() => saveMonthlyYieldChart()}
              data={
                generateGraphData(result.pumpResult.flows.monthsFlowsPerDay, result.pumpResult.flows.monthsFlowsPerDayPercent)
              } />
          </div>
          <Typography component="p" variant="caption">
          * The percentage represents the total daily flow in relation to the maximum output capacity of the pump.
          </Typography>

          <br />

          <IrrigationResult onLoaded={(images) => setIrrigationImages(images)} />


          <br />
          <Typography component="p" variant="body1">
            For more technical specifications generate a PDF by clicking the
            button below.
          </Typography>



        </DialogContent>}
        <DialogActions>
          {!result.pumpResult.error && <>
          {(!dailyYieldChart || !monthlyYieldChart || !irrigationImagesLoaded) && <CircularProgress/>}
          {(dailyYieldChart && monthlyYieldChart && irrigationImagesLoaded) && <ViechiPdf fallback={<CircularProgress/>}>
                    {({ default: PDF }) => <PDF result={{
                        ...result,
                        dailyYieldChart,
                        monthlyYieldChart,
                        irrigationImages,
                        irrigationResult: irrigationResult?.data,
                        irrigationInput: irrigationInput?.data
                    }} userProfile={userProfile} />}
                </ViechiPdf>}



            {/* <Button
              color="primary"
              variant="contained"
              disabled={!dailyYieldChart || !monthlyYieldChart || !irrigationImagesLoaded}
              onClick={() => {
                dispatch(
                  addAudit("Veichi Sizing PDF", 1, result.pumpResult.drive.name)
                )
                navigate("/pdfveichi/", {
                  state: {
                    result,
                    dailyYieldChart,
                    monthlyYieldChart,
                    irrigationImages,
                    irrigationResult: irrigationResult?.data,
                    irrigationInput: irrigationInput?.data
                    // inputs: lastBasicPumpInput.pumpValues,
                    // type: lastBasicPumpInput.type,
                  },
                })
              }}
            >
              Generate PDF
            </Button> */}
            
            </>}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

const AppSizingVeichi = ({ location }) => {
  const [calculationLoading, setCalculationLoading] = useState(false)

  const {
    towns,
    userProfile,
    ready,
    veichiCalculation,
    irrigationInput,
    viechiDrives,
  } = useSelector(state => state.db)
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(getTowns())
    dispatch(getVeichiDrivesList())
  }, [])

  return (
    <Box mb={2}>
      <Typography component="h1" variant="h4">
        Veichi Drives
      </Typography>
      {ready &&
        userProfile &&
        userProfile.roles &&
        viechiDrives &&
        canAccessSizing(userProfile.roles) && (
          <>
            <br />
            <Typography component="p" variant="body1">
              Our sizing works out the best Veichi drive for your area. If you already have panels or want to use a specific drive you can use the manual sizing button.
            </Typography>
            {veichiCalculation &&
              veichiCalculation.data &&
              !veichiCalculation.loading && (
                <ResultDisplayDialog
                  result={{ ...veichiCalculation.data, irrigationInputs: irrigationInput?.data }}
                  show={true}
                  onClose={() => {
                    // setShowResultDisplay(null)
                    dispatch(
                      finishVeichiCalculation({
                        loading: false,
                        msg: null,
                        error: null,
                        data: null,
                      })
                    )
                  }}
                />
              )}

            {/* {calculationLoading && <CircularProgress/>} */}

            {((!towns || !towns.data) && (towns && !towns.error)) && (
              <>
                <CircularProgress />
                <span style={{ marginLeft: "20px" }}>Loading tool...</span>
              </>
            )}
            {(towns && towns.error) && (
              <>
                <Typography>Could not load towns, click retry to try again.</Typography>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => dispatch(getTowns())}
                >
                  Retry loading towns
                </Button>
              </>
            )}
            {towns && towns.data && (
              <>
                <CustomerForm
                  loading={calculationLoading}
                  initialValues={{
                    ...defaultValues,
                    town: towns.data.find(a => a.name === "Unknown"),
                  }}
                  saveResult={values => {
                    console.log('output', { values })
                    setCalculationLoading(true)
                    const {
                      powerRating,
                      flow,
                      town,
                      customer,
                      phone,
                      email,
                      voltage,
                      panelMake,
                      panelVMP,
                      panelVOC,
                      panelWatt,
                      manual,
                      drive,
                      numberOfStrings,
                      panelsPerString,
                      irrigationInfo,
                      hybrid,
                    } = values
                    // setIrrigationInputs(irrigationInfo)

                    setTimeout(() => {
                      dispatch(
                        doVeichiCalculation({
                          voltage,
                          powerRating,
                          town,
                          flow,
                          customer,
                          phone,
                          email,
                          panelMake,
                          panelVMP,
                          panelVOC,
                          panelWatt,
                          manual,
                          drive,
                          numberOfStrings,
                          panelsPerString,
                          hybrid,
                          drives: viechiDrives.data
                        })
                      )
                      // doCalculation(values)
                      // saveCalculateAudit()
                      setCalculationLoading(false)
                      // setCalculationValues(values)
                      // setShowSaved(null)
                    }, 300)
                    // setRecomendResultError(null)
                    // setRecomendResult(null)

                  }}
                />
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => navigate("/app/sizing")}
                >
                  Back to sizing tools
                </Button>


              </>
            )}
          </>
        )}
    </Box>
  )
}

export default AppSizingVeichi
