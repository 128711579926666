import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"
import Profile from "../components/profile"
import Container from "@material-ui/core/Container"
import AppProductList from "../components/product/list"
import AppSizing from "../components/sizing"
import AppProductDetail from "../components/product/detail"
import { useSelector } from "react-redux"
import { canAccessSizing, isRep } from "../utils/checkRoles"
import AppDownloads from "../components/downloads"
import { Alert, AlertTitle } from "@material-ui/lab"
import CircularProgress from "@material-ui/core/CircularProgress"
import Application from "../components/application"
import AppTraining from "../components/training"
import AppSupport from "../components/support"
import AppPriceList from "../components/price-list"
import AppProductsList from "../components/product/products"
import AppProductOrder from "../components/product/order"
import AppProductCart from "../components/product/cart"
import AppQuote from "../components/quote"
import AppRepQuote from "../components/quote/repQuote"
import AppQuoteHistory from "../components/quote/saved"
import AppProductOrderSage from "../components/product-order"
import * as Sentry from "@sentry/gatsby";
import AppProductsShare from "../components/product/share"

const NotFound = () => {
  const { loggedIn } = useSelector(state => state.db)

  return (
    <Container component="main" maxWidth="md">
      {!loggedIn && (
        <Alert style={{ marginBottom: "1rem", width: "100%" }} severity="error">
          <AlertTitle>Error</AlertTitle>
          You are not logged in and cannot view this page.
        </Alert>
      )}
      {loggedIn && (
        <Alert style={{ marginBottom: "1rem", width: "100%" }} severity="error">
          <AlertTitle>Error</AlertTitle>
          Sorry, you don't have access to this page or it has moved. If you have just registered
          please wait till we approve your request.
        </Alert>
      )}
      <Button variant="contained" color="primary" onClick={() => navigate("/")}>
        Go Home
      </Button>
    </Container>
  )
}

const AppPage = () => {
  const { loggedIn, userProfile, ready } = useSelector(state => state.db)

  const loggedInAndReady = loggedIn && ready
  const normalSizing = loggedInAndReady && canAccessSizing(userProfile.roles)
  const rep = loggedInAndReady && isRep(userProfile.roles)

  // const stockCount = loggedInAndReady && canAccessStock(userProfile.roles)

  useEffect(() => {
    if (loggedIn) {
      Sentry.setContext("user", {
        id: userProfile.id,
      });
    }
  }, [loggedIn, userProfile])

  return (
    <Layout>
      <SEO title="App" />
      <Router>
        {loggedInAndReady && (
          <>
            <Profile path="/app/profile" />
            <Application path="/app/application/" />
          </>
        )}

        {normalSizing && (
          <>
            <AppProductList path="/app/product/categories/" />
            {/* <AppProductList path="/app/product/" /> */}
            <AppProductsList path="/app/product/categories/products/" />
            <AppProductCart path="/app/cart/" />

            <AppProductDetail path="/app/product/categories/products/detail/" />
            <AppProductsShare path="/app/product/share/" />
            <AppSupport path="/app/support/" />
            <AppTraining path="/app/training/" />
            <AppDownloads path="/app/downloads/" />
            <AppPriceList path="/app/price-list/" />
            {/* <AppProductOrder path="/app/order" /> */}
            <AppSizing path="/app/sizing/*" />
            {rep ? <AppRepQuote path="/app/quote/*" /> : <AppQuote path="/app/quote/*" />}

            <AppQuoteHistory path="/app/quote/saved" />
            <AppProductOrderSage path="/app/products-order/" />

          </>
        )}

        {ready && <NotFound default />}
      </Router>
      {!ready && <CircularProgress />}
    </Layout>
  )
}

export default AppPage
