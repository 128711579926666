import React, { useContext } from "react"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import Img from "gatsby-image"
import { addAudit } from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"
import { useStaticQuery, graphql } from "gatsby"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { roundCashFormat } from "../../utils/common"
import {
    ShoppingCart,
} from "@material-ui/icons"
import IconButton from "@material-ui/core/IconButton"
import debounce from "lodash/debounce";
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { useEffect } from "react"

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    gridList: {
        // width: 500,
        // height: 450,
    },
    root: {
        maxWidth: 345,
        height: '100%'
    },
    media: {
        height: 160,
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    table: {
        overflowX: "auto"
    }
}))


const AppProductCart = ({ location }) => {


    return (
        <Container component="main" maxWidth="md">

            <Typography component="h1" variant="h4" gutterBottom>
                Shopping Cart
            </Typography>

            <Box mb={2}>
                <Typography component="h2" variant="h5">
                    Orders have moved, please go to the home page and order or click here: <Link to="/app/products-order/">Orders</Link>.
                </Typography>
            </Box>


        </Container>
    )
}

export default AppProductCart
