import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button"
import writeXlsxFile from 'write-excel-file'
import { roundCashFormat, sortProducts, getPriceBasedOnRoleNocodb, getRoleTextOutput } from "../../utils/common"
import { useSelector, useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"
import dateFormat from 'dateformat'


export default function RetailPriceDownload({ productsList }) {

    const [data, setData] = useState([])

    useEffect(() => {
        if (productsList && productsList.length > 0) {
            // split into array of categories
            const categories = productsList.filter(a => !!a.Categories).reduce((acc, curr) => {
                if (!acc.includes(curr.Categories.Title)) {
                    acc.push(curr.Categories.Title)
                }
                return acc
            }, [])
            const temp = categories.map((category) => {
                const products = productsList.filter((product) => product.Categories.Title === category)
                return [[{ fontWeight: 'bold', span: 5, backgroundColor: '#C1C1C1', align: 'center', value: category }], ...products.map((product) => [
                    { value: product.Categories.Title },
                    { value: product.Code },
                    { value: product.Title },
                    { value: product?.["Retail Price"] },
                ])]
            }).flat()
            setData(temp)
        }
    }, [productsList])

    // console.log(productsList, userProfile)

    const saveExcel = async () => {

        const topRow = [{ fontWeight: 'bold', fontSize: 18, span: 5, align: 'center', backgroundColor: '#C1C1C1', value: `CEDAR SOLAR RETAIL PRICE LIST (Exported on ${dateFormat(new Date(), 'dd/mm/yyyy')})` }]

        const secondRow = [{ fontSize: 12, span: 5, align: 'center', backgroundColor: '#C1C1C1', value: `This is a suggested retail price only; you are responsible for setting your own pricing, and we cannot provide guidance on the prices at which you should sell the goods.` }]

        const headerRow = [{ fontWeight: 'bold', value: `Category` }, { fontWeight: 'bold', value: `Code` }, { fontWeight: 'bold', value: `Description` }, { fontWeight: 'bold', value: `Retail Price` }]

        const columns = [
            { width: 15 },
            { width: 30 },
            { width: 80 }, // in characters
            { width: 10 },
        ]

        await writeXlsxFile([topRow, secondRow, headerRow, ...data], {
            columns,
            fileName: `cedar-solar-recommended-retail-price-list-${dateFormat(new Date(), 'dd/mm/yyyy')}.xlsx`
        })
    }

    return (
        <Button variant="contained" color="primary" onClick={() => saveExcel()}>Recommended Retail</Button>
    );
}