import React from "react"
import { Router } from "@reach/router"
import { navigate } from "gatsby"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { useSelector, useDispatch } from "react-redux"
import AppSizingBasic from "./basic"
import Box from "@material-ui/core/Box"
import {
  PowerInput,
  AllInclusive,
  History,
  House,
} from "@material-ui/icons"
import AppSizingVeichi from "./veichi"
import AppSizingHistory from "./history"
// import AppSizingVictron from "./victron"
// import AppSizingSolar from "./solar"
import { clearPumpCalculation, clearVeichiCalculation } from "../../state/store"


const AppSizingMain = () => {
  const dispatch = useDispatch()

  return (
    <Box>
      <Box mb={2}>
        <Typography component="h1" variant="h3">
          Sizing Tools
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography component="h2" variant="h5">
          Use the following tools to help you size pumps and other products.
        </Typography>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "1rem",
          }}
          role="button"
          onClick={() => {
            dispatch(clearPumpCalculation())

            navigate("/app/sizing/basic/", { state: { type: "Pumps" } })
          }}
        >
          <PowerInput color="primary" style={{ fontSize: "50px" }} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(clearPumpCalculation())
              navigate("/app/sizing/basic/", { state: { type: "Pumps" } })
            }}
          >
            Pumps Sizing
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "1rem",
          }}
          onClick={() => {
            dispatch(clearVeichiCalculation())

            navigate("/app/sizing/veichi/", { state: { type: "Veichi" } })
          }}
        >
          <AllInclusive color="primary" style={{ fontSize: "50px" }} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(clearVeichiCalculation())
              navigate("/app/sizing/veichi/", { state: { type: "Veichi" } })
            }}
          >
            Veichi Drives
          </Button>
        </div>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "1rem",
          }}
          onClick={() => {
            dispatch(clearVeichiCalculation())

            navigate("/app/sizing/victron/", { state: { type: "victron" } })
          }}
        >
          <AllInclusive color="primary" style={{ fontSize: "50px" }} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(clearVeichiCalculation())
              navigate("/app/sizing/victron/", { state: { type: "victron" } })
            }}
          >
            Victron
          </Button>
        </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "1rem",
          }}
          onClick={() => navigate("/app/sizing/history/")}
        >
          <History color="secondary" style={{ fontSize: "50px" }} />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/app/sizing/history/")}
          >
            Saved Sizings
          </Button>
        </div>
      </div>
    </Box>
  )
}

const AppSizing = () => {
  const { ready } = useSelector(state => state.db)

  return (
    <Container component="main" maxWidth="md">
      <Router>
        {ready && <AppSizingBasic path="basic" />}
        {ready && <AppSizingVeichi path="veichi" />}
        {/* {ready && <AppSizingRooftop path="rooftop" />} */}
        {ready && <AppSizingHistory path="history" />}

        {/* {ready && <AppSizingVictron path="victron" />} */}
        {/* {ready && <AppSizingSolar path="solar" />} */}



        <AppSizingMain path="/" />
        {/* <NotFound default /> */}
      </Router>
    </Container>
  )
}

export default AppSizing
