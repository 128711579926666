import React, { useEffect, useRef } from "react"
import { navigate } from "gatsby"
import {
  getValidationSchemaProfile,
} from "./validations"
import { Formik, Form, Field, ErrorMessage } from "formik"
// import Image from "../components/image"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import AlertDialog from "../components/dialog"

import { verifyEmail, finishUpdateProfile, updateUserProfile, requestLogout, uploadProfileFile, getFileUrl } from "../state/store"
import { useSelector, useDispatch } from "react-redux"

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Select, MenuItem, CardActionArea } from "@material-ui/core"
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    maxWidth: "400px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonText: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: 'center'
  },
  buttonVerify: {
    marginLeft: theme.spacing(1),
  },
}))

const ProfileForm = ({ saveResult, submitting, initialValues, userProfile }) => {
  const classes = useStyles()

  const [profileImage, setProfileImage] = React.useState(null);

  const profileImageRef = useRef(null);


  useEffect(async () => {
    if (userProfile && userProfile.profileImage) {
      const url = await getFileUrl(userProfile.profileImage)
      setProfileImage(url)
    }
  }, [userProfile])

  return (
    <Formik
      validationSchema={getValidationSchemaProfile}
      initialValues={{
        email: initialValues.email ? initialValues.email : "",
        firstName: initialValues.firstName ? initialValues.firstName : "",
        lastName: initialValues.lastName ? initialValues.lastName : "",
        phone: initialValues.phone ? initialValues.phone : "",
        companyName: initialValues.companyName ? initialValues.companyName : "",
        vatNumber: initialValues.vatNumber ? initialValues.vatNumber : "",
        address: initialValues.address ? initialValues.address : "",
        warehouse: initialValues.warehouse ? initialValues.warehouse : "Johannesburg",
      }}
      onSubmit={(values, actions) => {
        saveResult(values)
        actions.setSubmitting(false)
      }}
    >
      {({
        errors,
        isSubmitting,
      }) => (
        <Form className={classes.form}>
          <Field
            type="email"
            name="email"
          >
            {({ field, form }) => <>
              <FormControl required fullWidth variant="outlined" margin="normal">
                <InputLabel required htmlFor="email">Email</InputLabel>
                <OutlinedInput
                  id="email"
                  {...field}
                  error={
                    form.errors[field.name] && form.touched[field.name]
                      ? true
                      : false
                  }
                  autoComplete="email"
                  // autoFocus
                  labelWidth={50}
                  required
                />
                <ErrorMessage name="email">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            </>
            }
          </Field>
          <Field
            type="text"
            name="firstName"
          >
            {({ field, form }) => <>
              <FormControl required fullWidth variant="outlined" margin="normal">
                <InputLabel required htmlFor="firstName">First Name</InputLabel>
                <OutlinedInput
                  id="firstName"
                  {...field}
                  error={
                    form.errors[field.name] && form.touched[field.name]
                      ? true
                      : false
                  }
                  // autoFocus
                  labelWidth={80}
                  required
                />
                <ErrorMessage name="firstName">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            </>}
          </Field>
          <Field
            type="text"
            name="lastName"
          >
            {({ field, form }) => <>
              <FormControl required fullWidth variant="outlined" margin="normal">
                <InputLabel required htmlFor="lastName">Last Name</InputLabel>
                <OutlinedInput
                  id="lastName"
                  {...field}
                  error={
                    form.errors[field.name] && form.touched[field.name]
                      ? true
                      : false
                  }
                  // autoFocus
                  labelWidth={80}
                  required
                />
                <ErrorMessage name="lastName">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            </>}
          </Field>
          <Field
            type="text"
            name="phone"
          >
            {({ field, form }) => <>
              <FormControl required fullWidth variant="outlined" margin="normal">
                <InputLabel required htmlFor="phone">Phone</InputLabel>
                <OutlinedInput
                  id="phone"
                  {...field}
                  error={
                    form.errors[field.name] && form.touched[field.name]
                      ? true
                      : false
                  }
                  // autoFocus
                  labelWidth={50}
                  required
                />
                <ErrorMessage name="phone">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            </>}
          </Field>
          <Field
            type="text"
            name="companyName"
          >
            {({ field, form }) => <>
              <FormControl required fullWidth variant="outlined" margin="normal">
                <InputLabel required htmlFor="companyName">Business Name</InputLabel>
                <OutlinedInput
                  id="companyName"
                  {...field}
                  error={
                    form.errors[field.name] && form.touched[field.name]
                      ? true
                      : false
                  }
                  // autoFocus
                  labelWidth={120}
                  required
                />
                <ErrorMessage name="companyName">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            </>}
          </Field>
          <Field
            type="text"
            name="logofile"

          >
            {({ field, form }) => <>
              <FormControl fullWidth variant="outlined" margin="normal">
                <CardActionArea onClick={() => {
                  profileImageRef.current.click()
                }
                }>
                  {(form.values.logofile || !profileImage) && <div style={{ display: "flex", alignItems: "center" }}><AttachFileIcon
                    fontSize="large"
                    color={form.values.logofile ? "primary" : "disabled"}
                  /><Typography component="span">Business Logo</Typography></div>}
                  {(!form.values.logofile && profileImage) && <img style={{ maxWidth: "200px", maxHeight: "200px" }} src={profileImage} alt="profile" />}

                </CardActionArea>
                <input
                  id="file"
                  type="file"
                  name="logofile"
                  style={{ display: "none" }}
                  accept="image/png, image/jpeg"
                  ref={profileImageRef}
                  // error={
                  //   getIn(errors, field.name) &&
                  //   getIn(touched, field.name)
                  //     ? true
                  //     : false
                  // }
                  // autoFocus
                  // labelWidth={80}
                  // required
                  onChange={event => {
                    form.setFieldValue("logofile", event.currentTarget.files[0])
                  }}
                />

                {/* <OutlinedInput
                  id="aFile"
                  type="file"
                  name="aFile"
                  // error={
                  //   form.errors[field.name] && form.touched[field.name]
                  //     ? true
                  //     : false
                  // }
                  // autoFocus
                  // labelWidth={80}
                  // required
                  onChange={event => {
                    form.setFieldValue("aFile", event.currentTarget.files[0])
                  }}
                /> */}
                <ErrorMessage name="aFile">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            </>}
          </Field>

          <Field
            type="text"
            name="vatNumber"
          >
            {({ field, form }) => <>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="vatNumber">VAT No.</InputLabel>
                <OutlinedInput
                  id="vatNumber"
                  {...field}
                  error={
                    form.errors[field.name] && form.touched[field.name]
                      ? true
                      : false
                  }
                  // autoFocus
                  labelWidth={80}

                />
                <ErrorMessage name="vatNumber">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            </>}
          </Field>
          <Field
            type="text"
            name="address"
          >
            {({ field, form }) => <>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="address">Business Address</InputLabel>
                <OutlinedInput
                  id="address"
                  {...field}
                  error={
                    form.errors[field.name] && form.touched[field.name]
                      ? true
                      : false
                  }
                  // autoFocus
                  labelWidth={140}
                />
                <ErrorMessage name="address">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            </>}
          </Field>
          <Field
            type="text"
            name="warehouse">
            {({ field }) => (<>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="warehouse">Pickup Location</InputLabel>

                <Select
                  label="Pickup Location"
                  id="warehouse"
                  type="select"
                  name="warehouse"
                  {...field}
                >
                  <MenuItem value={"Johannesburg"}>Johannesburg</MenuItem>
                  <MenuItem value={"Cape Town"}>Cape Town</MenuItem>
                </Select>

                <ErrorMessage name="warehouse">
                  {msg => <FormHelperText>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            </>)}
          </Field>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={
              isSubmitting || Object.keys(errors).length > 0 || submitting
            }
            type="submit"
          >
            Save
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const Profile = () => {
  // const state = useContext(GlobalStateContext)
  // const [dispatch] = useContext(GlobalDispatchContext)
  const { userProfile, profile } = useSelector(state => state.db)
  const dispatch = useDispatch()

  const classes = useStyles()
  return (
    <Container component="main" maxWidth="md">
      {profile && profile.error && !profile.error.reauth && (
        <AlertDialog
          title="Error"
          message={profile.error.msg}
          closeDialog={() =>
            dispatch(
              finishUpdateProfile({ loading: false, msg: null, error: null })
            )
          }
        />
      )}

      {profile && profile.error && profile.error.reauth && (
        <Dialog
          open={true}
          onClose={() => null}
          aria-labelledby="login-reauth"
          aria-describedby="login reauth"
        >
          <DialogTitle>Security Error</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {profile.error.msg}
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              dispatch(requestLogout())
              navigate('/login/')
            }} color="primary">
              Login again
            </Button>
            <Button onClick={() => {
              dispatch(
                finishUpdateProfile({ loading: false, msg: null, error: null })
              )
            }} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {profile && profile.msg && (
        <AlertDialog
          title="Message"
          message={profile.msg}
          closeDialog={() =>
            dispatch(
              finishUpdateProfile({ loading: false, msg: null, error: null })
            )
          }
        />
      )}

      {profile && profile.loading && <CircularProgress />}
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Profile
        </Typography>
        <Typography component="p" variant="body1">
          Update your profile here. Changing your email may require you to login again.
        </Typography>
        {!userProfile.emailVerified && (
          <div className={classes.buttonText}>
            <Typography component="p" variant="body1">
              Verify your email please
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonVerify}
              onClick={() => dispatch(verifyEmail())}
            >
              Verify
            </Button>
          </div>
        )}
        {userProfile && (
          <ProfileForm
            initialValues={{
              email: userProfile.email,
              firstName: userProfile.firstName,
              lastName: userProfile.lastName,
              phone: userProfile.phone,
              companyName: userProfile.companyName,
              vatNumber: userProfile.vatNumber,
              address: userProfile.address,
              warehouse: userProfile.warehouse,
            }}
            userProfile={userProfile}
            saveResult={async (values) => {
              const { logofile, ...valuesNoFile } = values
              if (logofile) {
                await uploadProfileFile(logofile.name, logofile)
              }
              dispatch(updateUserProfile({ ...valuesNoFile, id: userProfile.id }))
            }}
          />
        )}
      </div>
    </Container>
  )
}

export default Profile
