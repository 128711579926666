import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import CircularProgress from "@material-ui/core/CircularProgress"
import { getCategoriesList, getProductsList } from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "gatsby"
import { Alert } from "@material-ui/lab"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
  root: {
    maxWidth: 345,
    height: '100%'
  },
  media: {
    height: 160,
    backgroundSize: 'contain'
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  breadcrumbLink: {
    textDecoration: 'none',
    '& :hover': {
      textDecoration: 'underline',
    }
  }
}))


function getParameterValue(parameterName, urlString) {
    // Use URLSearchParams to parse the URL string
    const urlParams = new URLSearchParams(urlString);
  
    // Get the value of the specified parameter
    const parameterValue = urlParams.get(parameterName);
  
    return parameterValue;
  }

const AppProductsShare = ({ location }) => {

  const { categories, products, userProfile } = useSelector(state => state.db)
  const dispatch = useDispatch()
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)

  const [showNoCategory, setShowNoCategory] = useState(false)

  useEffect(() => {
    if (didJustAddToCart) {
      setTimeout(() => {
        setDidJustAddToCart(false)
      }, 3000)
    }
  }, [didJustAddToCart])

  useEffect(() => {
    dispatch(getCategoriesList())
    dispatch(getProductsList())
  }, [])


  useEffect(() => {   
    if (location && location.search && categories && categories.data && products && products.data) {
        const productId = getParameterValue("id", location.search);

        const product = products.data.find(a => a.id == productId);

        if(product){
            navigate("/app/product/categories/products/detail/", { state: { product: product, collectionName: product.Categories.Title, collectionId: product.Categories.Id}})
        }
        else{
          setShowNoCategory(true)
        }

    }
  }, [location, products, categories])

  const classes = useStyles()
  return (
    <Container component="main" maxWidth="md">

      {!showNoCategory && <CircularProgress />}


      {showNoCategory && <Alert className={classes.marginBottom} severity="warning" color="warning">
        Cannot find the product. Please go to the <Link to="/app/product/categories/">Product Categories</Link> to find the product. <br/>Or contact support, the product may not be on the store yet.
      </Alert>}

     
    </Container>
  )
}

export default AppProductsShare
