import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import {
  addApplication,
  finishApplication,
} from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import { canAccessAgentApplication } from "../../utils/checkRoles"
import { Formik, Form, Field, ErrorMessage } from "formik"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

import { getValidationSchemaAgentApplication } from "../validations"
import AlertDialog from "../dialog"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
}))

const NewApplication = () => {
  const classes = useStyles()
  const { application, userProfile } = useSelector(state => state.db)

  const dispatch = useDispatch()
  return (
    <div>
      {!canAccessAgentApplication(userProfile.roles) && !application &&(
        <AlertDialog
          title="Already Completed"
          message={
            "You have already applied, please wait for us to review the application. If this is an error please send us an email at info@cedarsolar.com"
          }
          closeDialog={() => navigate("/")}
        />
      )}
      {application && application.loading && <CircularProgress />}
      {application && application.error && (
        <AlertDialog
          title="Error"
          message={application.error.msg}
          closeDialog={() =>
            dispatch(
              finishApplication({
                loading: false,
                msg: null,
                error: null,
                data: null,
              })
            )
          }
        />
      )}

      {application && application.msg && (
        <AlertDialog
          title="Application Success"
          message={application.msg}
          closeDialog={() => {
            dispatch(
              finishApplication({
                loading: false,
                msg: null,
                error: null,
                data: null,
              })
            )
            navigate("/")
          }}
        />
      )}
      <Formik
        validationSchema={getValidationSchemaAgentApplication}
        initialValues={{
          firstName: "",
          lastName: "",
          workEmail: "",
          phone: "",
          businessName: "",
          job: "Owner",
          deliveryAddress: "",
          province: "Gauteng",
          town: "",
          vatNumber: "",
          warehouse: "Johannesburg",
        }}
        onSubmit={(values, actions) => {
          dispatch(addApplication(values))
          actions.setSubmitting(false)
        }}
      >
        {({
          errors,
          isSubmitting,
        }) => (
          <Form className={classes.form}>
            <Field
              type="text"
              name="firstName"
              render={({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="firstName">First Name</InputLabel>
                  <OutlinedInput
                    id="firstName"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    // autoFocus
                    labelWidth={80}
                    required
                  />
                  <ErrorMessage name="firstName">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />
            <Field
              type="text"
              name="lastName"
              render={({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="lastName">Last Name</InputLabel>
                  <OutlinedInput
                    id="lastName"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    // autoFocus
                    labelWidth={80}
                    required
                  />
                  <ErrorMessage name="lastName">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />
            <Field
              type="email"
              name="workEmail"
              render={({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="email">Work Email</InputLabel>
                  <OutlinedInput
                    id="email"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    autoComplete="email"
                    // autoFocus
                    labelWidth={90}
                    required
                  />
                  <ErrorMessage name="workEmail">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />
            <Field
              type="text"
              name="phone"
              render={({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="phone">Your Phone</InputLabel>
                  <OutlinedInput
                    id="phone"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    // autoFocus
                    labelWidth={90}
                    required
                  />
                  <ErrorMessage name="phone">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />
            <Field
              type="text"
              name="businessName"
              render={({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="businessName">Business Name</InputLabel>
                  <OutlinedInput
                    id="businessName"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    // autoFocus
                    labelWidth={120}
                    required
                  />
                  <ErrorMessage name="businessName">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />
            <Field
              type="text"
              name="job"
              render={({ field }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="job">Job</InputLabel>

                  <Select
                    labelWidth={50}
                    id="job"
                    type="select"
                    name="job"
                    {...field}
                  >
                    <MenuItem value={"Owner"}>Owner</MenuItem>
                    <MenuItem value={"Manager"}>Manager</MenuItem>
                    <MenuItem value={"Installer"}>Installer</MenuItem>
                    <MenuItem value={"Sales Rep"}>Sales Rep</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>

                  <ErrorMessage name="job">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />

            <Field
              type="text"
              name="deliveryAddress"
              render={({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="deliveryAddress">
                    Delivery Address
                  </InputLabel>
                  <OutlinedInput
                    id="deliveryAddress"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    // autoFocus
                    labelWidth={120}
                    required
                  />
                  <ErrorMessage name="deliveryAddress">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />

            <Field
              type="text"
              name="town"
              render={({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="town">Town</InputLabel>
                  <OutlinedInput
                    id="town"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    // autoFocus
                    labelWidth={50}
                    required
                  />
                  <ErrorMessage name="town">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />

            <Field
              type="text"
              name="province"
              render={({ field }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="province">Province</InputLabel>

                  <Select
                    labelWidth={60}
                    id="province"
                    type="select"
                    name="province"
                    {...field}
                  >
                    <MenuItem value={"Eastern Cape"}>Eastern Cape</MenuItem>
                    <MenuItem value={"Free State"}>Free State</MenuItem>
                    <MenuItem value={"Gauteng"}>Gauteng</MenuItem>
                    <MenuItem value={"KwaZulu-Natal"}>KwaZulu-Natal</MenuItem>
                    <MenuItem value={"Limpopo"}>Limpopo</MenuItem>
                    <MenuItem value={"Mpumalanga"}>Mpumalanga</MenuItem>
                    <MenuItem value={"North West"}>North West</MenuItem>
                    <MenuItem value={"Northern Cape"}>Northern Cape</MenuItem>
                    <MenuItem value={"Western Cape"}>Western Cape</MenuItem>
                    <MenuItem value={"Outside SA"}>Outside SA</MenuItem>
                  </Select>

                  <ErrorMessage name="province">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />

            <Field
              type="text"
              name="vatNumber"
              render={({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="vatNumber">VAT Number</InputLabel>
                  <OutlinedInput
                    id="vatNumber"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    // autoFocus
                    labelWidth={90}
                  />
                  <ErrorMessage name="vatNumber">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />

          <Field
              type="text"
              name="warehouse"
              render={({ field }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="warehouse">Pickup Location</InputLabel>

                  <Select
                    label="Pickup Location"
                    id="warehouse"
                    type="select"
                    name="warehouse"
                    {...field}
                  >
                    <MenuItem value={"Johannesburg"}>Johannesburg</MenuItem>
                    <MenuItem value={"Cape Town"}>Cape Town</MenuItem>
                  </Select>

                  <ErrorMessage name="warehouse">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />

            <br />

            <Button
              variant="contained"
              color="primary"
              //   fullWidth
              disabled={isSubmitting || Object.keys(errors).length > 0 || (application && application.loading)}
              type="submit"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const Application = () => {
  return (
    <Container component="main" maxWidth="md">
      <Typography component="h2" variant="h6">
        Agent application
      </Typography>
      <Typography component="p" variant="body1">
        To access the benefits of our agent portal. Please fill out the
        following application about your business or company you work for. Once
        reviewed by our team you will get access to all the features.
      </Typography>
      <NewApplication />
    </Container>
  )
}

export default Application
