import React, { useEffect } from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import { getDownloads } from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import groupBy from "lodash/groupBy"
import toPairs from "lodash/toPairs"
import Box from "@material-ui/core/Box"
import { saveAs } from "file-saver"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
}))

const AppDownloads = () => {
  // const state = useContext(GlobalStateContext)
  // const [dispatch] = useContext(GlobalDispatchContext)
  const { downloads } = useSelector(state => state.db)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDownloads())
  }, [])
  const classes = useStyles()
  return (
    <Container component="main" maxWidth="md">
      <Box mb={2}>
        <Typography component="h1" variant="h4">
          Downloads
        </Typography>
        <Typography component="p" variant="body1">
          Get the price list here: <Link to="/app/price-list">Price List</Link>
        </Typography>
      </Box>
      {downloads && downloads.loading && <CircularProgress />}
      {downloads &&
        downloads.data &&
        toPairs(groupBy(downloads.data, "category")).map(a => (
          <div key={a[0]}>
            <Box mt={2}>
              <Typography component="h3" variant="h5">
                {a[0]}
              </Typography>
            </Box>
            <Table aria-label="Table" style={{ overflowX: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">File</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {a[1].map(b => (
                  <TableRow key={b.id}>
                    <TableCell>{b.title}</TableCell>
                    <TableCell>{b.description}</TableCell>
                    <TableCell align="right">
                      {!b.file && <span>No file</span>}
                      {b.file && !b.fileUrl && <CircularProgress />}
                      {b.file && b.fileUrl && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => saveAs(b.fileUrl)}
                        >
                          Download Here
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ))}
    </Container>
  )
}

export default AppDownloads
