import React, { useEffect, useContext, useState } from "react"
import { navigate } from "gatsby"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import GridList from "@material-ui/core/GridList"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"
import { getCategoriesList, getProductsList } from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import uniqBy from "lodash/uniqBy"
import Box from "@material-ui/core/Box"
import { useStaticQuery, graphql, Link } from "gatsby"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import CardActionArea from "@material-ui/core/CardActionArea"
import stripHtml from "string-strip-html"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { convertImageFromNocodb } from "../../utils/common"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
  root: {
    maxWidth: 345,
    height: '100%'
  },
  media: {
    height: 160,
    backgroundSize: 'contain'
  },
}))

const AppProductList = () => {

  const { categories, products } = useSelector(state => state.db)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCategoriesList())
    dispatch(getProductsList())
  }, [])

  const classes = useStyles()
  return (
    <Container component="main" maxWidth="md">
      <Box mb={2}>
        <Typography component="h1" variant="h4">
          Product Categories
        </Typography>
      </Box>

      <Grid container spacing={2}>
      {categories && categories.loading && <CircularProgress />}
      {categories && categories.data && categories.data.map(collection=>
      <Grid key={collection.id} item xs={12} sm={6} md={3}>
           <Card 
           className={classes.root}
           >
           <CardActionArea onClick={() =>
              navigate("/app/product/categories/products", {
                state: {collectionId: collection.id, collectionTitle: collection.Title},
              })
            }>
             <CardMedia
             className={classes.media}
              image={collection.Image?convertImageFromNocodb(collection.Image, 'tr:h-200,cm-pad_resize,bg-FFFFFF')??'/no-product.jpg':'/no-product.jpg'}
               title={collection.Title}
             />
             <CardContent>
               <Typography gutterBottom variant="h5" component="h2">
                 {collection.Title}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p">
                 {collection.Description}
               </Typography>
             </CardContent>
           </CardActionArea>
           <CardActions>
             {/* <Button size="small" color="primary">
               Share
             </Button> */}
             <Button size="small" color="primary"
             onClick={() =>
              navigate("/app/product/categories/products", {
                state: {collectionId: collection.id},
              })
            }
             >
               See products
             </Button>
           </CardActions>
         </Card></Grid>)}
         </Grid>
      
    </Container>
  )
}

export default AppProductList
