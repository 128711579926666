import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Link } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
  root: {
    width: "100%",
  },
  detail: {
    display: "block",
  },
  // heading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   fontWeight: theme.typography.fontWeightRegular,
  // },
}))

const categories = [
  "General",
  "Premium Pumps",
  "Spitfire Pumps",
  "MultiFlow",
  "Reseller Portal",
]

const videos = [
  {
    id: 1,
    cats: ["Premium Pumps"],
    title: "Wiring the Ceva, Bovem & Fortis Solar Pump Controller",
    url: "https://www.youtube.com/embed/qe1DLZ_nBm0",
  },
  {
    id: 2,
    cats: ["Spitfire Pumps"],
    title: "Wiring The Controller",
    url: "https://www.youtube.com/embed/6OvDyjFp1gg",
  },
  {
    id: 3,
    cats: ["Spitfire Pumps"],
    title: "Basic Controller Navigation",
    url: "https://www.youtube.com/embed/1-5cWtOyxug",
  },
  {
    id: 4,
    cats: ["Spitfire Pumps"],
    title: "Resetting The Controller",
    url: "https://www.youtube.com/embed/xv3onL3-ak0",
  },
  {
    id: 5,
    cats: ["Spitfire Pumps"],
    title: "Well/Dry Run Protection Installation",
    url: "https://www.youtube.com/embed/pVPnbb6C4Zs",
  },
  {
    id: 6,
    cats: ["Spitfire Pumps"],
    title: "Turning Internal Dry Run Protection ON & OFF",
    url: "https://www.youtube.com/embed/kd5t60RTTH4",
  },
  {
    id: 7,
    cats: ["Spitfire Pumps"],
    title: "Installing The Tank Level Sensor",
    url: "https://www.youtube.com/embed/ZIzuTUvqbqM",
  },
  {
    id: 8,
    cats: ["Spitfire Pumps"],
    title: "Permanently Change Speed Settings",
    url: "https://www.youtube.com/embed/hSCCX2ZBnAU",
  },
  {
    id: 9,
    cats: ["MultiFlow"],
    title: "Connecting The Controller",
    url: "https://www.youtube.com/embed/pn3iQ_eHEtA",
  },
  {
    id: 10,
    cats: ["MultiFlow"],
    title: "General Navigation & Selecting Working Modes",
    url: "https://www.youtube.com/embed/VoF0FzNNCk0",
  },
  {
    id: 11,
    cats: ["MultiFlow"],
    title: "Toggling between Pressure & Speed Operation Modes",
    url: "https://www.youtube.com/embed/aGWl9Y9ZwRo",
  },
  {
    id: 12,
    cats: ["MultiFlow"],
    title: "Changing The Speed Permanently",
    url: "https://www.youtube.com/embed/AMIv4COg4jM",
  },
  {
    id: 13,
    cats: ["MultiFlow"],
    title: "Installing & Programming a Tank Sensor",
    url: "https://www.youtube.com/embed/CctBkjVff_s",
  },
  {
    id: 14,
    cats: ["MultiFlow"],
    title: "Switching between Hybrid, AC, DC, & Auto Modes",
    url: "https://www.youtube.com/embed/MBpYiUMGA_o",
  },
  {
    id: 15,
    cats: ["Premium Pumps", "Spitfire Pumps", "MultiFlow"],
    title: "Solar Panels: Understanding Series & Parallel Connections",
    url: "https://www.youtube.com/embed/0Bx7_qNcCd8",
  },
  {
    id: 16,
    cats: ["Premium Pumps", "MultiFlow"],
    title: "Replacing the Helical Rotor of a Solar Water Pump",
    url: "https://www.youtube.com/embed/PsJtHN0O3Bg",
  },
  {
    id: 17,
    cats: ["Reseller Portal"],
    title: "Sizing solar pumps in the Cedar Solar Reseller Portal",
    url: "https://www.youtube.com/embed/0fROJM5FnSA",
  },
  {
    id: 18,
    cats: ["Premium Pumps", "Spitfire Pumps", "MultiFlow"],
    title: "How to make a cable joint for a solar water pump",
    url: "https://www.youtube.com/embed/TeCNtyuqdOc",
  },
  {
    id: 19,
    cats: ["General"],
    title: "Checking & Replacing the Rotor on Spitfire & Ceva DC Solar Pumps",
    url: "https://www.youtube.com/embed/H8sqHYLPXMI",
  }
]


const AppTraining = () => {
  const classes = useStyles()
  return (
    <Container component="main" maxWidth="md">
      <Box mb={2}>
        <Typography component="h1" variant="h4">
          Training
        </Typography>
        <Typography component="h2" variant="body1">
          Videos are grouped into product ranges and categories, please click on
          the arrow to show videos based on the category.
        </Typography>
        <Typography variant="body1">
          Send your clients a link to videos <Link href="https://www.cedarsolar.com/downloads">www.cedarsolar.com/downloads</Link>
        </Typography>
        <br />
        <div className={classes.root}>
          {categories.map(cat => (
            <Accordion key={cat}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={cat}
                id={cat}
              >
                <Typography component="h3" variant="h5">
                  {cat}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.detail}>
                {videos
                  .filter(b => b.cats.includes(cat))
                  .map(video => (
                    <div key={`${video.id}${cat}`}>
                      <Typography>{video.title}</Typography>
                      <br />
                      <iframe
                        title={video.title}
                        width={
                          window.innerWidth < 500 ? window.innerWidth - 20 : 560
                        }
                        height="315"
                        src={video.url}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                      <br />
                      <br />
                    </div>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>

        <br />
        <br />
        <Typography component="h4" variant="h6">
          Subscribe for more at{" "}
          <a
            href="http://www.youtube.com/cedarsolar"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cedar Solar YouTube
          </a>
        </Typography>
      </Box>
    </Container>
  )
}

export default AppTraining
