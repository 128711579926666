import React from 'react';
import Error from "@material-ui/icons/Error"
import CheckCircle from "@material-ui/icons/CheckCircle"
import { getStockValue, IN_STOCK, OUT_OF_STOCK } from '../utils/common';
import { Box } from '@material-ui/core';


export default function StockIcon({ stock }) {
    const title = getStockValue(stock)
    return (
        <Box px={1} lineHeight={'normal'}>
            {title === OUT_OF_STOCK && <Error style={{ color: 'red' }} aria-label={title} title={title} />}
            {title === IN_STOCK && <CheckCircle style={{ color: 'green' }} aria-label={title} title={title} />}
        </Box>
    );
}