import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import { addSupportTicket, finishSupportTicket, getSupportTickets } from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import dateFormat from "dateformat"

import { Formik, Form, Field, ErrorMessage } from "formik"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"


import { getValidationSchemaTicket } from "../validations"
import AlertDialog from "../dialog"

// const isBrowser = typeof window !== `undefined`

// let firebase = null
// if(isBrowser){
//   firebase = require("firebase/app")
//   require('firebase/firestore')
// }

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
}))

const NewTicket = () => {
  const classes = useStyles()
  const { ticket, userProfile } = useSelector(state => state.db)

  const dispatch = useDispatch()
  return (
    <div>
      <Typography component="h3" variant="h6">
        Make a support request
      </Typography>
      <Typography component="p" variant="body1">
        Please fill out the form below to submit a support request. If your request involves a product please add the serial number and product name if you have it. If you would rather send more details please use the email <a href="mailto:support@cedarsolar.com">support@cedarsolar.com</a>.
      </Typography>
      {ticket && ticket.loading && <CircularProgress />}
      {ticket && ticket.error && (
        <AlertDialog
          title="Error"
          message={ticket.error.msg}
          closeDialog={() =>
            dispatch(
              finishSupportTicket({
                loading: false,
                msg: null,
                error: null,
                data: null,
              })
            )
          }
        />
      )}

      {ticket && ticket.msg && (
        <AlertDialog
          title="Success"
          message={ticket.msg}
          closeDialog={() =>
            dispatch(
              finishSupportTicket({
                loading: false,
                msg: null,
                error: null,
                data: null,
              })
            )
          }
        />
      )}
      {/* <Formik
        validationSchema={getValidationSchemaTicket}
        initialValues={{
          subject: "",
          email: userProfile&&userProfile.email?userProfile.email:"",
          description: "",
          serialNumber: "",
          product: "",
        }}
        onSubmit={(values, actions) => {
          const { ...rest } = values
          dispatch(
            addSupportTicket({
              ...rest,
              name: userProfile&&userProfile.firstName?userProfile.firstName + ' ' + userProfile.lastName:'',
              company: userProfile&&userProfile.companyName?userProfile.companyName:''
            })
          )
          actions.setSubmitting(false)
        }}
      >
        {({
          errors,
          isSubmitting,
          handleReset,
        }) => (
          <Form className={classes.form}>
            <Field
              type="text"
              name="subject"
              render={({ field, form }) => (
                <FormControl required fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="subject">
                    Subject of Request
                  </InputLabel>
                  <OutlinedInput
                    id="subject"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    labelWidth={130}
                    required
                    placeholder="Pump defect"
                  />
                  <ErrorMessage name="subject">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />
            <Field
              type="email"
              name="email"
              render={({ field, form }) => (
                <FormControl required fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <OutlinedInput
                    id="email"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    autoComplete="email"
                    labelWidth={50}
                    required
                  />
                  <ErrorMessage name="email">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />
            <Field
              type="text"
              name="description"
              render={({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel required htmlFor="description">Description of the Request</InputLabel>
                  <OutlinedInput
                    id="description"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    // autoFocus
                    labelWidth={200}
                    required
                    multiline
                    rows="4"
                  />
                  <ErrorMessage name="description">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />

            <Field
              type="text"
              name="serialNumber"
              render={({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="serialNumber">Serial Number</InputLabel>
                  <OutlinedInput
                    id="serialNumber"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    labelWidth={120}
                  />
                  <ErrorMessage name="serialNumber">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />

            <Field
              type="text"
              name="product"
              render={({ field, form }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="product">Product name or description</InputLabel>
                  <OutlinedInput
                    id="product"
                    {...field}
                    error={
                      form.errors[field.name] && form.touched[field.name]
                        ? true
                        : false
                    }
                    labelWidth={200}
                  />
                  <ErrorMessage name="product">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
            />


            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting || Object.keys(errors).length > 0}
              type="submit"
            >
              Submit
            </Button>{" "}
            <Button variant="contained" color="primary" onClick={handleReset}>
              Clear
            </Button>
          </Form>
        )}
      </Formik> */}

      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScLnN4C1HnA57YLfleusqkK5Nu4An3Zi4rAsae3KY7xItAVKw/viewform?embedded=true" width="640" height="382" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  )
}

const TicketRow = ({ ticket }) => {
  const [currentUser] = useState(ticket)
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {currentUser.date
          ? dateFormat(
            new Date(currentUser.date.seconds * 1000),
            "dd mmm yyyy"
          )
          : ""}
      </TableCell>
      <TableCell component="th" scope="row">
        {currentUser.subject}
      </TableCell>
      <TableCell component="th" scope="row">
        {currentUser.email}
      </TableCell>
      <TableCell component="th" scope="row">
        {currentUser.sent ? currentUser.sent : "sending"}
      </TableCell>
    </TableRow>
  )
}

const TicketList = () => {
  const { tickets } = useSelector(state => state.db)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSupportTickets())
  }, [dispatch])

  return (
    <>
      <Typography component="h1" variant="h5">
        Sent Tickets
      </Typography>
      <Typography component="p" variant="body1">
        List of tickets sent to the support service. Tickets are resolved via another service so this only shows if they have been sent to the support service.
      </Typography>


      {tickets && tickets.loading && <CircularProgress />}

      {/* <Button onClick={() => dispatch(getWarrantyList())}>Refresh</Button> */}
      <div style={{ overflowX: "auto" }}>
        <Table aria-label="tickets Table" >
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Sent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets &&
              tickets.data &&
              tickets.data.map(row => (
                <TicketRow key={row.id} ticket={row} />
              ))}
          </TableBody>
        </Table>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatch(getSupportTickets())}
        >
          Refresh
        </Button>
      </div>
    </>
  )
}

const AppSupport = () => {
  const [tabValue, setTabValue] = React.useState(0)



  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    // dispatch(getProducts())
  }, [])
  return (
    <Container component="main" maxWidth="md">
      <Box mb={2}>
        <Typography component="h1" variant="h4">
          Support Ticket
        </Typography>
      </Box>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="New Support Ticket" />
        <Tab label="List of Sent Tickets" />
      </Tabs>
      {tabValue === 0 && <NewTicket />}
      {tabValue === 1 && <TicketList />}
    </Container>
  )
}

export default AppSupport
