import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useSelector, useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"
import dateFormat from "dateformat"
import {
  setBasicPumpSizingFromSaved,
  removeSizingSave,
  getSizingSaveList,
} from "../../state/store"
import MaterialTable from "material-table"

import {
  Edit,
  Delete,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  Clear,
  ArrowUpward,
  Add,
  Check,
} from "@material-ui/icons"

const HistoryList = () => {
  const { userProfile, sizingSaves } = useSelector(state => state.db)
  const dispatch = useDispatch()

  useEffect(() => {
    if (userProfile.id) {
      dispatch(getSizingSaveList())
    }
  }, [userProfile])
  return (
    <>
      {sizingSaves && userProfile && sizingSaves.loading && (
        <CircularProgress />
      )}
      {sizingSaves && sizingSaves.loading === false && sizingSaves.data && sizingSaves.data.length === 0 && (
        <Typography mt="10px" color="error" component="p" variant="body1">
          You have no saved sizings. Do a sizing first.
        </Typography>
      )}
      <div style={{ overflowX: "auto" }}>
        {sizingSaves && sizingSaves.data && sizingSaves.data.length > 0 && (
          <MaterialTable
            icons={{
              Edit,
              Delete,
              FirstPage,
              LastPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              ResetSearch: Clear,
              Search,
              SortArrow: ArrowUpward,
              Add,
              Check,
              Clear,
            }}
            columns={[
              {
                title: "Date",
                field: "date",
                searchable: false,
                render: rowData => (
                  <span>
                    {rowData.date &&
                      dateFormat(
                        new Date(rowData.date.seconds * 1000),
                        "dd mmm yyyy HH:MM"
                      )}
                  </span>
                ),
              },
              { title: "Type", field: "type", searchable: false },
              {
                title: "Customer",
                field: "pumpValues.customer",
                render: rowData => <span>{rowData.pumpValues.customer}</span>,
              },
              {
                title: "Email",
                field: "pumpValues.email",
                render: rowData => <span>{rowData.pumpValues.email}</span>,
              },
              {
                title: "Water Needed",
                field: "pumpValues.waterNeeded",
                searchable: false,
                render: rowData => (
                  <span>{rowData.pumpValues.waterNeeded}</span>
                ),
              },
            ]}
            actions={[
              () => ({
                icon: Edit,
                tooltip: "Show",
                onClick: (event, rowData) => {
                  dispatch(setBasicPumpSizingFromSaved(rowData.pumpValues))
                  navigate("/app/sizing/basic/", {
                    state: { type: rowData.type },
                  })
                },
              }),
              () => ({
                icon: Delete,
                tooltip: "Delete",
                onClick: (event, rowData) =>
                  dispatch(removeSizingSave(rowData.id)),
              }),
            ]}
            options={{
              pageSize: 20,
              actionsColumnIndex: -1,
            }}
            data={JSON.parse(JSON.stringify(sizingSaves.data.filter(a=>a.pumpValues!==undefined)))}
            title="Saved sizings"
          />
        )}
      </div>
    </>
  )
}

const AppSizingHistory = () => {
  //   const { products } = useSelector(state => state.db)
  return (
    <>
      <Box mb={2}>
        <Typography component="h1" variant="h4">
          Saved sizings
        </Typography>
        <Typography component="p" variant="body1">
          See the sizings you've saved here. When doing a sizing look out for
          the save for later button. Support for all the sizing tools coming in
          the future.
        </Typography>
      </Box>
      <HistoryList />
    </>
  )
}

export default AppSizingHistory
