import React, { useEffect, useContext, useState, useReducer } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"
import { useSelector, useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Button from "@material-ui/core/Button"
import sum from 'lodash/sum'
import { roundCashFormat, sortProducts, getPriceBasedOnRoleNocodb, getStockBasedOnProfileLocation, getRetailPrice } from "../../utils/common"
import { Table, TableRow, TableCell, TableBody, Grid } from '@material-ui/core';
import loadable from '@loadable/component'
import { Formik, Form, Field, ErrorMessage } from "formik"
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { getValidationSchemaQuote, getValidationSchemaClientQuoteInputs } from '../validations';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { updateOrCreateQuote, getCategoriesList, getProductsList, addToCurrentOrder, addMultipleToCurrentOrder } from '../../state/store';
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { bom } from "./bom"
import { Alert } from "@material-ui/lab"
import { v4 as uuidv4 } from 'uuid';
import { ResultDisplayDialog } from '../sizing/basic';
import { quoteInternalRefGenerator, quoteRefGenerator, ClientQuoteInputs, ExtraQuoteInputs, ProductSearchBox } from './index';
import { isNamibia } from '../../utils/checkRoles';

const BasicPdf = loadable.lib(() => import('../sizing/basicPdf'))

const useStyles = makeStyles(theme => ({
    autoComp: {
        lineHeight: 0
    }
}))

const markupFromRetailPrice = ({ retailPrice, price }) => {
    if (retailPrice && price) {
        if (retailPrice === price) {
            return 10
        }
        return Math.round((parseFloat(retailPrice) / parseFloat(price) - 1) * 100)
    } else {
        return 0
    }
}

const priceWithMarkup = ({ price }) => (price || 0)

const subtotal = ({ price, quantity }) => (price || 0) * (quantity || 0)

const totalNoMarkup = (rows) => sum(rows.map(a => (a.quantity || 0) * (a.priceInstaller || 0)))
const totalWithMarkup = (rows) => sum(rows.map(a => (subtotal({ price: a.priceInstaller, quantity: a.quantity }))))

const CustomFooter = ({ rows, showVat }) => {
    return <Table>
        <TableBody>
            <TableRow>
                <TableCell align="right">
                    <Typography style={{ fontWeight: 'bold' }}>
                        Total (ex VAT)
                    </Typography>
                </TableCell>
                <TableCell align="right" width={100}>
                    {roundCashFormat(totalWithMarkup(rows))}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right">
                    <Typography style={{ fontWeight: 'bold' }}>
                        VAT
                    </Typography>
                </TableCell>
                <TableCell align="right" width={100}>
                    {roundCashFormat(totalWithMarkup(rows) * 0.15)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right">
                    <Typography style={{ fontWeight: 'bold' }}>
                        Total
                    </Typography>
                </TableCell>
                <TableCell align="right" width={100}>
                    {roundCashFormat(totalWithMarkup(rows) * 1.15)}
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>

}

// import QuotePdf from './quote-pdf';
const QuotePdf = loadable.lib(() => import('./quote-pdf'))

const initialQuoteState = {
    // productsList: [],
    client: null,
    rows: [],
    // selectedRows: [],
    quoteRef: '',
    notes: '',
    showVat: true,
    internalRef: null, //quoteInternalRefGenerator(),
    // showEdit: false
}


const AppRepQuote = ({ location }) => {

    const dispatch = useDispatch()

    const { userProfile, products, categories } = useSelector(state => state.db)

    const sizingResult = location?.state?.sizingData?.result ?? location?.state?.saved?.sizingData?.result

    const sizingInputs = location?.state?.sizingData?.inputs ?? location?.state?.saved?.sizingData?.inputs

    const quoteReducer = (state, action) => {
        switch (action.type) {
            case 'SET_QUOTE_INTERNAL_REF':
                return { ...state, internalRef: action.payload };
            case 'SET_QUOTE_REF':
                dispatch(updateOrCreateQuote({ ...state, quoteRef: action.payload, sizingData: location.state.sizingData }))
                return { ...state, quoteRef: action.payload };
            case 'SET_NOTES':
                dispatch(updateOrCreateQuote({ ...state, notes: action.payload, sizingData: location.state.sizingData }))
                return { ...state, notes: action.payload };
            case 'SET_ROWS':
                dispatch(updateOrCreateQuote({ ...state, rows: action.payload, sizingData: location.state.sizingData }))
                return { ...state, rows: action.payload };
            case 'SET_CLIENT':
                dispatch(updateOrCreateQuote({ ...state, client: action.payload, sizingData: location.state.sizingData }))
                return { ...state, client: action.payload };
            case 'SET_SHOW_VAT':
                dispatch(updateOrCreateQuote({ ...state, showVat: action.payload, sizingData: location.state.sizingData }))
                return { ...state, showVat: action.payload };
            case 'SET_FROM_SAVED':
                return { ...action.payload };
            default:
                throw new Error();
        }
    }

    const [quoteState, quoteDispatch] = useReducer(quoteReducer, initialQuoteState);

    const [productsList, setProductsList] = useState([]) // the list of products from shopify

    const [selectedRows, setSelectedRows] = useState([])

    const [showEdit, setShowEdit] = useState(null)

    const [didJustAddToCart, setDidJustAddToCart] = useState(false)

    const [showReportDetail, setShowReportDetail] = useState(false)

    // console.log('quoteState', quoteState)

    useEffect(() => {
        if (didJustAddToCart) {
            setTimeout(() => {
                setDidJustAddToCart(false)
            }, 3000)
        }
    }, [didJustAddToCart])

    useEffect(() => {
        dispatch(getCategoriesList())
        dispatch(getProductsList())
    }, [])

    useEffect(() => {
        if (userProfile && userProfile.roles && products && products.data && products.data.length > 0 && categories && categories.data) {
            // making sure there is a category for each product
            const productsList = products.data.filter(a => !!a.Categories && categories.data.find(category => `${category.Id}` === `${a.Categories.Id}`)).map(p => {
                return {
                    ...p,
                    // category: category ? category : { Title: 'Uncategorised' },
                    price: getPriceBasedOnRoleNocodb({ roles: userProfile.roles, product: p }),
                    stock: getStockBasedOnProfileLocation({ location: userProfile?.warehouse, product: p }),
                }
            })
            setProductsList(productsList)

        }
    }, [products, categories, userProfile])

    useEffect(() => {
        if (!userProfile || !userProfile?.roles) {
            return
        }
        if (location?.state?.saved && productsList && productsList.length > 0) {
            quoteDispatch({ type: 'SET_FROM_SAVED', payload: location.state.saved })
            return
        }

        const panelCode = productsList && sizingResult && sizingResult?.panelCode === "Jinko 440" && isNamibia(userProfile.roles) ? "Jinko 395" : sizingResult?.panelCode
        const bomList = productsList && sizingResult && bom({
            pumpProductCode: sizingResult?.productCode,
            waterLevel: sizingResult?.waterLevel,
            tdh: sizingResult?.totalDynamicHead,
            installDepth: sizingResult?.installDepth,
            pumpType: sizingResult?.type,
            panelCode: panelCode,
            noPanels: sizingResult?.panels,
        })

        if (bomList && bomList.length > 0 && productsList && productsList.length > 0) {
            quoteDispatch({
                type: 'SET_ROWS',
                payload: bomList
                    .filter((b) => !!b?.productId)
                    .filter((b) => !!productsList.find((a) => a.Code === b.productId.toString()))
                    .map((a, i) => {
                        const product = productsList.find((b) => b.Code === a.productId.toString())
                        return {
                            id: uuidv4(),
                            name: product.Title,
                            product: product,
                            priceInstaller: getRetailPrice({ roles: userProfile.roles, product }),
                            quantity: a.quantity,
                            stock:
                                product[
                                'JHB Stock'
                                ] +
                                product[
                                'CPT Stock'
                                ],
                            markup: 0,
                            // markup: markupFromRetailPrice({ retailPrice: getRetailPrice({ roles: userProfile.roles, product }), price: product.price }),
                        }
                    }),
            })
        }
    }, [sizingResult, productsList, location, userProfile])

    useEffect(() => {
        if (userProfile && userProfile?.companyName && quoteState.quoteRef === '' && !location?.state?.saved) {
            quoteDispatch({ payload: quoteRefGenerator(userProfile.companyName), type: 'SET_QUOTE_REF' })
        }
    }, [userProfile])


    useEffect(() => {
        if (location?.state?.saved && productsList && productsList.length > 0) {
            return
        }
        if (sizingResult && sizingResult?.customer) {
            quoteDispatch({
                type: 'SET_CLIENT', payload: {
                    customer: sizingResult?.customer,
                    email: sizingResult?.email,
                    phone: sizingResult?.phone,
                }
            })
        }
    }, [sizingResult, location])

    useEffect(() => {
        if (location?.state?.saved) {
            quoteDispatch({ type: 'SET_QUOTE_INTERNAL_REF', payload: location.state.saved.internalRef })
        } else {
            quoteDispatch({ type: 'SET_QUOTE_INTERNAL_REF', payload: quoteInternalRefGenerator() })
        }
    }, [location])

    const pdfReady = quoteState.rows && quoteState.rows.length > 0 && quoteState.rows.filter(a => a.name?.Title || a.name).length > 0


    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            minWidth: 300,
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            editable: true,
            valueGetter: (params) => {
                if (params.row.product && params.row.product.Title) {
                    return params.row.product.Title
                } else {
                    return params.row.name ?? ''
                }
            }
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            width: 100,
            editable: true,
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,

        },
        {
            field: 'priceInstaller',
            headerName: 'Price',
            type: 'number',
            width: 160,
            editable: true,
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            valueFormatter: (params) => roundCashFormat(params.value)

        },
        {
            field: 'price',
            headerName: 'Retail Price',
            type: 'number',
            width: 150,
            editable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            valueFormatter: (params) => roundCashFormat(params.value),
            sortable: false,
            filterable: false,
            valueGetter: (params) =>
                `${priceWithMarkup({ price: params.getValue(params.id, 'priceInstaller') })}`,
        },
        {
            field: 'subtotal',
            headerName: 'Sub Total',
            type: 'number',
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            valueFormatter: (params) => roundCashFormat(params.value),
            width: 160,
            valueGetter: (params) =>
                `${subtotal({ price: params.getValue(params.id, 'priceInstaller'), quantity: params.getValue(params.id, 'quantity') })}`,
        },
    ];

    return (
        <Container component="main" maxWidth="lg">
            {showReportDetail && <ResultDisplayDialog inputs={sizingInputs} hideQuote={true} show={showReportDetail} result={sizingResult} onClose={() => setShowReportDetail(false)} />}
            <Box mb={2}>
                <Typography component="h1" variant="h4">
                    Quote your client (REP)
                </Typography>
            </Box>
            <Typography component="p" variant="body1" gutterBottom>
                Use the table below to make a quote for you client. Add items to the quote and edit the line items as you would like. Download the PDF so you can send it to your client.
            </Typography>
            <div>
                <Dialog
                    open={!!showEdit}
                    onClose={() => setShowEdit(null)}
                    aria-labelledby="edit-dialog-title"
                >{showEdit && <>
                    <DialogTitle id="edit-dialog-title">{showEdit.title}</DialogTitle>
                    <DialogContent>
                        {showEdit.name === 'client' && <ClientQuoteInputs inputs={{ customer: quoteState.client?.customer, email: quoteState.client?.email, phone: quoteState.client?.phone, address: quoteState.client?.address }} onSaved={(values) => {

                            quoteDispatch({ type: 'SET_CLIENT', payload: values })

                            setShowEdit(null)
                        }
                        } />}
                        {showEdit.name === 'quote' && <ExtraQuoteInputs inputs={{ quoteReference: quoteState.quoteRef, notes: quoteState.notes }} onSaved={(values) => {

                            quoteDispatch({ type: 'SET_QUOTE_REF', payload: values.quoteReference })
                            quoteDispatch({ type: 'SET_NOTES', payload: values.notes })

                            setShowEdit(null)
                        }
                        } />}

                    </DialogContent>
                </>}
                </Dialog>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box marginBottom={'10px'}>

                        <Typography variant='h6'>Client Info</Typography>
                        {quoteState?.client && <>
                            <Typography variant='subtitle2'>Client: {quoteState.client?.customer}</Typography>
                            <Typography variant='subtitle2'>Email: {quoteState.client?.email}</Typography>
                            <Typography variant='subtitle2'>Phone: {quoteState.client?.phone}</Typography>
                            <Typography variant='subtitle2'>Address: {quoteState.client?.address}</Typography>

                        </>}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setShowEdit({ title: 'Edit Client Information', name: 'client' })
                            }}
                        >
                            Edit
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box marginBottom={'10px'}>
                        <Typography variant='h6'>Your Info</Typography>
                        <Typography variant='subtitle2'>{userProfile.companyName}</Typography>
                        <Typography variant='subtitle2'>{userProfile.email}</Typography>
                        <Typography variant='subtitle2'>{userProfile.phone}</Typography>
                        <Typography variant='subtitle2'>VAT no: {userProfile.vatNumber}</Typography>
                        <Typography variant='subtitle2'>Address: {userProfile.address}</Typography>


                        <Typography variant='body2'>*You can edit this information on you profile page</Typography>

                    </Box>
                    <Box marginBottom={'10px'}>
                        <Typography>Quote Ref: {quoteState.quoteRef}</Typography>
                        <Typography>Extra Information:</Typography>
                        <Typography>{quoteState.notes}</Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setShowEdit({ title: 'Edit Quote Information', name: 'quote' })
                            }}
                        >
                            Edit
                        </Button>

                    </Box>
                </Grid>
            </Grid>
            {/* <Box marginBottom={'10px'}>
            <ExtraQuoteInputs/>
            </Box> */}
            <Box marginBottom={'10px'}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={e => quoteDispatch({ type: 'SET_SHOW_VAT', payload: e.target.checked })}
                            checked={quoteState.showVat}
                        />
                    }
                    label="Show VAT breakdown in PDF Quote"
                />
            </Box>
            <Box marginBottom={'10px'}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>

                        quoteDispatch({ type: 'SET_ROWS', payload: [{ id: uuidv4(), quantity: 1, priceInstaller: 0, markup: 15 }, ...quoteState.rows] })
                    }
                >
                    Add Row
                </Button>
                <Button
                    style={{ marginLeft: '20px' }}
                    variant="contained"
                    color="primary"
                    disabled={!(selectedRows && selectedRows.length > 0)}
                    onClick={() => {
                        quoteDispatch({ type: 'SET_ROWS', payload: quoteState.rows.filter(a => !selectedRows.find(b => b === a.id)) })
                        setSelectedRows([])

                    }}
                >
                    Delete Selected
                </Button>
            </Box>

            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={quoteState.rows}
                    columns={columns}
                    pageSize={30}
                    rowsPerPageOptions={[5, 10]}
                    checkboxSelection
                    disableSelectionOnClick
                    rowHeight={80}
                    onSelectionModelChange={(e) => {
                        setSelectedRows(e)
                    }}
                    onCellEditCommit={(e) => {
                        quoteDispatch({
                            type: 'SET_ROWS',
                            payload: quoteState.rows.map(a => {
                                if (e.row && (a.id === e.row.id)) {
                                    let row = e.row
                                    row[e.field] = e.value
                                    return { ...row }
                                } else if (a.id === e.id) {
                                    let row = a
                                    row[e.field] = e.value
                                    return { ...row }
                                } else {
                                    return a
                                }

                            })
                        })

                    }}
                    components={{
                        Footer: CustomFooter
                    }}
                    componentsProps={{
                        footer: { rows: quoteState.rows, showVat: quoteState.showVat },
                    }}
                />
            </div>
            {/* <Box marginTop={'10px'} marginBottom={'10px'}>

                {(quotes && quotes.loading) ?
                    <CircularProgress /> :

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            // if we have an id it came from the DB, so update
                            if (quoteState.id) {
                                dispatch(updateQuoteSave(quoteState))
                            } else {
                                dispatch(addQuoteSave(quoteState))
                            }
                        }}
                    >
                        {quoteState.id ? `Update Saved Quote` : `Save Quote`}
                    </Button>}
                {" "}
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        quoteDispatch({ type: 'SET_FROM_SAVED', payload: initialQuoteState })
                        quoteDispatch({ payload: quoteRefGenerator(userProfile.id), type: 'SET_QUOTE_REF' })
                    }}
                >
                    New Quote
                </Button>

            </Box> */}
            {didJustAddToCart && <Alert severity="success" color="success">
                Product added, see the top menu for the Shopping Cart.
            </Alert>}
            <Box marginTop={'10px'} marginBottom={'10px'}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        const cartItems = quoteState.rows
                            .filter((row) => !!row.product)
                            .map((row) => {
                                return {
                                    product: row.product,
                                    quantity: parseInt(row.quantity),
                                    id: row.product.Id,
                                    name: row.product.Title,
                                    price: getPriceBasedOnRoleNocodb({ roles: userProfile.roles, product: row.product }),
                                    stock: getStockBasedOnProfileLocation({ location: userProfile?.warehouse, product: row.product }),
                                    code: row.product.Code,
                                }
                            })
                        dispatch(addMultipleToCurrentOrder(cartItems))
                        setDidJustAddToCart(true)
                    }}
                >
                    {didJustAddToCart ? `Added to cart` : `Add to cart`}
                </Button>
            </Box>
            {/* {pdfReportReady && <Box marginTop={'10px'} marginBottom={'10px'}>
                <BasicPdf fallback={<CircularProgress/>}>
                    {({ default: PDF }) => <PDF resultOutput={location.state.pdf} />}
                </BasicPdf>
            </Box>} */}
            <Box marginTop={'10px'} marginBottom={'10px'}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setShowReportDetail(true)
                    }}
                >
                    Sizing Report
                </Button>
            </Box>
            {pdfReady && <Box marginTop={'10px'} marginBottom={'10px'}>
                <QuotePdf fallback={<CircularProgress />}>
                    {({ default: PDF }) => <PDF result={{
                        sizing: location.state.sizingData,
                        client: quoteState.client,
                        installer: userProfile,
                        quoteRef: quoteState.quoteRef,
                        internalRef: quoteState.internalRef,
                        notes: quoteState.notes,
                        showVat: quoteState.showVat,
                        total: totalWithMarkup(quoteState.rows),
                        rows: quoteState.rows.map(a => ({ ...a, price: priceWithMarkup({ price: a.priceInstaller }), subtotal: subtotal({ price: a.priceInstaller, quantity: a.quantity }) }))
                    }} />}
                </QuotePdf>

            </Box>}

            {(location.state && location.state.sizing) && <Box marginTop={'10px'} marginBottom={'10px'}>
                {/* (location.state && location.state.sizing) */}
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        window.history.back()
                    }}
                >
                    Back to sizing
                </Button>
            </Box>}



            {/* <Box marginTop={'10px'} marginBottom={'10px'}>
                <Typography variant='subtitle2'>*You can access this quote later if you save/update it.</Typography>
            </Box> */}


        </Container>)

}

export default AppRepQuote
