import React, { useEffect, useState } from "react"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useSelector, useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"
import { roundCashFormat, sortProducts, getPriceBasedOnRoleNocodb, getRoleTextOutput } from "../../utils/common"
import StockIcon from '../stockIcon';
import { DataGrid } from '@mui/x-data-grid';
import { getCategoriesList, getProductsList } from '../../state/store';
import XLSX from 'xlsx'
import dateFormat from 'dateformat'
import Button from "@material-ui/core/Button"
import writeXlsxFile from 'write-excel-file'
// import PricePdf from "./price-pdf"
import loadable from '@loadable/component'
import roundTo from 'round-to'
import RetailPriceDownload from "./retail-price-download"

const PricePdf = loadable.lib(() => import('./price-pdf'))

const AppPriceList = () => {
  const { products, categories, userProfile } = useSelector(state => state.db)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProductsList())
    dispatch(getCategoriesList())
  }, [])

  const [productsList, setProductsList] = useState([])


  useEffect(() => {
    if (products && products.data && categories && categories.data) {

      const productsWithCategories = products.data
        .map(product => ({ ...product, Categories: !!product.Categories && categories.data.find(category => `${category.Id}` === `${product.Categories.Id}`) ? categories.data.find(category => `${category.Id}` === `${product.Categories.Id}`) : null }))
        .filter(product => !!product.Categories)

      setProductsList(sortProducts(productsWithCategories))
    }
  }, [products, categories])

  const [data, setData] = useState([])

  useEffect(() => {
    // console.log('stuff', productsList && productsList.length>0 && userProfile && userProfile.roles)
    if (productsList && productsList.length > 0 && userProfile && userProfile.roles) {
      // split into array of categories
      const categories = productsList.filter(a => !!a.Categories).reduce((acc, curr) => {
        if (!acc.includes(curr.Categories.Title)) {
          acc.push(curr.Categories.Title)
        }
        return acc
      }, [])
      const temp = categories.map((category) => {
        const products = productsList.filter((product) => product.Categories.Title === category)
        return [[{ fontWeight: 'bold', span: 5, backgroundColor: '#C1C1C1', align: 'center', value: category }], ...products.map((product) => [
          { value: product.Categories.Title },
          { value: product.Code },
          { value: product.Title },
          { value: parseFloat(getPriceBasedOnRoleNocodb({ roles: userProfile.roles, product })) },
          { value: roundTo(getPriceBasedOnRoleNocodb({ roles: userProfile.roles, product }) * 1.15, 2) }
        ])]
      }).flat()
      setData(temp)
    }
  }, [productsList, userProfile])

  // console.log(productsList, userProfile)

  const saveExcel = async () => {

    const topRow = [{ fontWeight: 'bold', fontSize: 18, span: 5, align: 'center', backgroundColor: '#C1C1C1', value: `CEDAR SOLAR PRICE LIST (Exported on ${dateFormat(new Date(), 'dd/mm/yyyy')})` }]

    const headerRow = [{ fontWeight: 'bold', value: `Category` }, { fontWeight: 'bold', value: `Code` }, { fontWeight: 'bold', value: `Description` }, { fontWeight: 'bold', value: `Excl. VAT Price` }, { fontWeight: 'bold', value: `Inc. VAT Price` }]

    const columns = [
      { width: 15 },
      { width: 30 },
      { width: 80 }, // in characters
      { width: 10 },
      { width: 10 }
    ]

    await writeXlsxFile([topRow, headerRow, ...data], {
      columns,
      fileName: `cedar-solar-price-list-${dateFormat(new Date(), 'dd/mm/yyyy')}.xlsx`
    })
  }

  const columns = [
    {
      field: 'Categories', headerName: 'Category',
      width: 200,
      hideSortIcons: false,
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      valueFormatter: (params) => params.row.Categories.Title,
    },
    {
      field: 'Code', headerName: 'Code',
      width: 160,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'Title', headerName: 'Title', flex: 1,
      maxWidth: 600,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'price',
      headerName: 'Price (excl VAT)',
      type: 'number',
      width: 200,
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueFormatter: (params) => roundCashFormat(getPriceBasedOnRoleNocodb({ roles: userProfile.roles, product: params.row }))

    },
    {
      field: 'priceIncl',
      headerName: 'Price (incl VAT)',
      type: 'number',
      width: 200,
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueFormatter: (params) => roundCashFormat(getPriceBasedOnRoleNocodb({ roles: userProfile.roles, product: params.row }) * 1.15)

    },
  ];

  return (
    <Container component="main" maxWidth="lg">
      <Box mb={2}>
        <Typography gutterBottom component="h1" variant="h4">
          Price List
        </Typography>
        <Typography gutterBottom component="p" variant="body1">
          Download the {(userProfile && userProfile.roles) ? getRoleTextOutput({ roles: userProfile.roles }) : ""} price list in PDF or Excel below.
        </Typography>
      </Box>

      {productsList && productsList.length === 0 && <CircularProgress />}

      {productsList && userProfile && userProfile.roles &&
        <>
          <Box mb={2} display={'flex'} >
            <Box>
              <Button variant="contained" color="primary" onClick={() => saveExcel()}>Download Excel</Button>
            </Box>
            <Box ml={2} >
              <PricePdf fallback={<CircularProgress />}>
                {({ default: PDF }) => <PDF data={data} />}
              </PricePdf>
            </Box>
            <Box ml={2} >
              <RetailPriceDownload productsList={productsList} />
            </Box>
          </Box>
          <div style={{ height: '800px', width: '100%' }}>
            <DataGrid
              rows={productsList}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[100]}
              disableSelectionOnClick
              pagination
              rowHeight={80}

            />
          </div>
        </>}

    </Container>
  )
}

export default AppPriceList
