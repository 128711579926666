import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Image from "material-ui-image"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import { roundCashFormat, convertImageFromNocodb, getPriceBasedOnRoleNocodb, getStockBasedOnProfileLocation } from "../../utils/common"
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { Alert } from "@material-ui/lab"
import { useSelector, useDispatch } from "react-redux"
import { getCurrentOrder, updateCurrentOrder, addOrder, finishAddOrder, addToCurrentOrder } from '../../state/store';
import { Divider } from "@material-ui/core"
import { parse } from 'marked'
import { canAccessStock } from '../../utils/checkRoles'

const useStyles = makeStyles(theme => {
  // https://stackoverflow.com/questions/66028355/material-ui-styles-and-html-markdown
  const nestedRules = {};
  nestedRules[`& p`] = { ...theme.typography['body1'], margin: 0 };
  nestedRules[`& ul`] = { ...theme.typography['body1'] };
  nestedRules[`& ol`] = { ...theme.typography['body1'] };
  return ({
    markdownText: nestedRules,
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    gridList: {
      // width: 500,
      // height: 450,
    },
    root: {
      maxWidth: 345,
      height: '100%'
    },
    media: {
      height: 160,
    },
    paddingBottom: {
      paddingBottom: theme.spacing(2)
    },
    breadcrumbLink: {
      textDecoration: 'none',
      '& :hover': {
        textDecoration: 'underline',
      }
    }

  })
})



const AppProductDetail = ({ location }) => {
  const classes = useStyles()

  const { userProfile } = useSelector(state => state.db)
  const dispatch = useDispatch()

  const [product, setProduct] = useState(null)

  const [quantity, setQuantity] = useState(1)

  const [didJustAddToCart, setDidJustAddToCart] = useState(false)

  const [showNoProduct, setShowNoProduct] = useState(false)

  useEffect(() => {
    if (didJustAddToCart) {
      setTimeout(() => {
        setDidJustAddToCart(false)
      }, 3000)
    }
  }, [didJustAddToCart])


  useEffect(() => {
    if(location && location.state === null){
      setShowNoProduct(true)
    }
    else
    if (location.state && location.state.product) {
      setProduct(location.state.product);
    }
  }, [location])

  // const renderElement = useCallback(props => <Element {...props} />, [])
  return (
    <Container component="main" maxWidth="md">
      <Breadcrumbs aria-label="breadcrumb" className={classes.paddingBottom}>
        <Link className={classes.breadcrumbLink} to="/app/product/categories/"><Typography color="textPrimary">Product Categories</Typography></Link>
        <Link className={classes.breadcrumbLink} to="/app/product/categories/products/" state={{ collectionId: location?.state?.collectionId }}><Typography color="textPrimary">{location?.state?.collectionName}</Typography></Link>

        <Typography color="textPrimary">{product?.Title}</Typography>
      </Breadcrumbs>
      {!product && !showNoProduct && <CircularProgress />}
      {showNoProduct && <Alert className={classes.marginBottom} severity="warning" color="warning">
        No product selected. Please head back to the Product Categories page. <Link to="/app/product/categories/">Go to Product Categories</Link>
      </Alert>}
      {userProfile && product && (
        <Box p={1}>
          <Box mb={1}>
            <Typography component="h1" variant="h4" gutterBottom>
              {product.Title}
            </Typography>

          </Box>
          {/* <Divider variant="middle" /> */}
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              mb={2}
              style={{ minHeight: "400px", paddingTop: 0, maxWidth: "400px", overflow: "hidden" }}
            >
              {/* {<Image style={{backgroundSize: 'contain', marginBottom: "20px"}} imageStyle={{}} src={product.Image?convertImageFromNocodb(product.Image, 'tr:w-400,cm-pad_resize,bg-FFFFFF')??'/default.png':'/default.png'} />} */}
              {<img style={{ backgroundSize: 'contain', maxWidth: '400px', marginBottom: "20px" }} src={product.Image ? convertImageFromNocodb(product.Image, 'tr:w-400,cm-pad_resize,bg-FFFFFF') ?? '/no-product.jpg' : '/no-product.jpg'} />}

            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom variant="h6" component="b">
                {/* {roundCashFormat(product.variants[0].price)} */}
                {roundCashFormat(getPriceBasedOnRoleNocodb({ roles: userProfile.roles, product }))}
              </Typography>
              {/* <Typography variant="subtitle1" color="textSecondary" gutterBottom component="p">
                Stock: {getStockBasedOnProfileLocation({ location: userProfile?.warehouse, product })}
              </Typography> */}
              <Typography variant="subtitle1" color="textSecondary" gutterBottom component="p">
              <b>JHB Stock:</b> {product['JHB Stock']}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom component="p">
              <b>CPT Stock:</b> {product['CPT Stock']}
              </Typography>

              <Typography gutterBottom variant="body1"
                className={classes.markdownText}
                dangerouslySetInnerHTML={{ __html: parse(product.Description, { breaks: true }) }}>
              </Typography>




              {product.Datasheet && <>
                <Box my={'10px'}>
                  {product.Datasheet.map(file => (
                    <Button style={{marginBottom:"5px"}} key={file.url} variant="outlined" href={file.url} download target="_blank">{file.title}</Button>
                  ))}

                </Box>

              </>}

              <Box my={'20px'} />
            </Grid>
          </Grid>

          <Box mb={2}>
            {/* {product.variants.length > 1 && <><FormControl variant="outlined">
              <InputLabel id="variant-select">Option</InputLabel>
              <Select
                labelId="variant-select"
                id="variant-select"
                value={currentVariant}
                //   onChange={handleChange}
                labelWidth={50}
                onChange={e => {
                  setCurrentVariant(e.target.value)
                }}
              >
                {product.variants.map(a => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.title} ({roundCashFormat(product.variants[0].price)})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>{" "}</>} */}

            <FormControl
              required
              variant="outlined"

            >
              <InputLabel htmlFor="quantity">Quantity</InputLabel>
              <OutlinedInput
                id="quantity"
                type="number"
                inputMode="numeric"
                error={quantity < 1
                }
                onChange={(e) => setQuantity(e.target.value)}
                // autoFocus
                value={quantity}
                labelWidth={70}
                required
              />
            </FormControl>

          </Box>

          <Box mb={2}>
            {/* <Button
              onClick={() => navigate("/app/product/list/", { replace: true })}
              variant="contained"
              color="primary"
            >
              Buy Now
            </Button> */}
            <Button
              // disabled={quantity < 1 || getStockBasedOnProfileLocation({ location: userProfile?.warehouse, product }) < quantity}
              disabled={quantity < 1 }
              onClick={() => {
                // addVariantToCart(currentVariant, quantity);
                // console.log('quantity', quantity)
                const productToAdd = { id: product.id, product, name: product.Title, price: getPriceBasedOnRoleNocodb({ roles: userProfile.roles, product }), stock: parseInt(product['JHB Stock']), code: product.Code, quantity: parseInt(quantity) }
                dispatch(addToCurrentOrder(productToAdd))
                setDidJustAddToCart(true)
              }}

              variant="contained"
              color="primary"
            >
              {didJustAddToCart ? `Added to cart` : `Add to cart`}

            </Button>
          </Box>

          {didJustAddToCart && <Alert severity="success" color="success">
            Product added, see the top menu for the Shopping Cart.
          </Alert>}

        </Box>
      )}
    </Container>
  )
}

export default AppProductDetail
