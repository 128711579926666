import { max } from 'lodash'

const getPipeType = (pipeRatingLength) => {
    if (pipeRatingLength <= 50) {
        return "C6"
    } else if (pipeRatingLength <= 100) {
        return "C10"
    } else if (pipeRatingLength <= 150) {
        return "C16"
    } else {
        return "TooBig"
    }
}

const getPipeProduct = (pipeType, pump) => {
    switch (pump) {
        case "Bovem 140":
        case "Bovem 70":
        case "Ceva 100":
        case "Ceva 55":
        case "Spitfire 100":
        case "Spitfire 203":
        case "Spitfire 50":
        case "Spitfire 80":
        case "Multiflow 300":
            if (pipeType === "C6") {
                return "HDPE Pipe 32mm C6 50m"
            } else if (pipeType === "C10") {
                return "HDPE Pipe 32mm C10"
            }
            break;
        case "Fortis 130":
        case "Multiflow 200":
            if (pipeType === "C6") {
                return "HDPE Pipe 40mm C6 50m"
            } else if (pipeType === "C10") {
                return "HDPE Pipe 40mm C10"
            } else if (pipeType === "C16") {
                return "HDPE Pipe 40mm C16 150m"
            }
            break;
        case "Multiflow 110":
            if (pipeType === "C6") {
                return "HDPE Pipe 50mm C6 50m"
            } else if (pipeType === "C10") {
                return "HDPE Pipe 50mm C10 100m"
            }
            break;
        default:
            return null
    }
}

const getFittingProduct = (pipeType, pump) => {
    switch (pump) {
        case "Bovem 140":
        case "Bovem 70":
        case "Ceva 100":
        case "Ceva 55":
        case "Spitfire 100":
        case "Spitfire 203":
        case "Spitfire 50":
        case "Spitfire 80":
        case "Multiflow 300":
            if (pipeType === "C6") {
                return "32mm Fittings Pack"
            } else if (pipeType === "C10") {
                return "32mm Fittings Pack"
            }
            break;
        case "Fortis 130":
        case "Multiflow 200":
            if (pipeType === "C6") {
                return "40mm Fittings Pack"
            } else if (pipeType === "C10") {
                return "40mm Fittings Pack"
            } else if (pipeType === "C16") {
                return "40mm Fittings Pack"
            }
            break;
        case "Multiflow 110":
            if (pipeType === "C6") {
                return "50mm Fittings Pack"
            } else if (pipeType === "C10") {
                return "50mm Fittings Pack"
            }
            break;
        default:
            return null
    }
}

const getBasePlate = (pump) => {
    switch (pump) {
        case "Bovem 140":
        case "Bovem 70":
        case "Ceva 100":
        case "Ceva 55":
        case "Spitfire 100":
        case "Spitfire 203":
        case "Spitfire 50":
        case "Spitfire 80":
        case "Multiflow 300":
            return "Baseplate 32mm"
        case "Fortis 130":
        case "Multiflow 200":
            return "Baseplate 40mm"
        case "Multiflow 110":
            return "Baseplate 50mm"
        default:
            return null
    }
}


export const bom = ({ pumpProductCode, waterLevel, tdh, installDepth, pumpType, panelCode, noPanels }) => {
    const cableLength = parseFloat(installDepth) + 5
    const ropeLength = parseFloat(installDepth) + 5

    const pipeRatingLength = max([parseFloat(installDepth), tdh])
    const pipeType = getPipeType(pipeRatingLength)
    const pipeProduct = getPipeProduct(pipeType, pumpProductCode)
    const fittngsProduct = getFittingProduct(pipeType, pumpProductCode)
    const basePlate = getBasePlate(pumpProductCode)

    let cableProduct = parseFloat(installDepth) > 100 ? 'Sub Cable 3-6' : 'Sub Cable 3-4'
    if (pumpType.toLowerCase() === 'multiflow') {
        cableProduct = parseFloat(installDepth) > 150 ? 'Sub Cable 4-4' : 'Sub Cable 4-2.5'
    }
    let ropeProduct = 'Rope 6mm'
    if (pumpType.toLowerCase() === 'multiflow' || parseFloat(installDepth) >= 100) {
        ropeProduct = 'Rope 8mm'
    }

    return [
        {
            productId: pumpProductCode,
            quantity: 1
        },
        {
            productId: pipeProduct,
            quantity: 1
        },
        {
            productId: cableProduct,
            quantity: cableLength
        },
        {
            productId: ropeProduct,
            quantity: ropeLength
        },
        {
            productId: panelCode,
            quantity: noPanels
        },
        {
            productId: fittngsProduct,
            quantity: 1
        },
        {
            productId: 'Cable Ties',
            quantity: 1
        },
        {
            productId: 'Cable Joint',
            quantity: 1
        },
        {
            productId: basePlate,
            quantity: 1
        }
    ].filter((item) => item.productId !== null)





    // HDPE Pipe 32mm C10
    // HDPE Pipe 40mm C10

    // return null
}